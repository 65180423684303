import React from "react"
import css from './RoundLoader.module.css'
import LoaderFreshSVG from "../../assets/svgs/LoaderFresh"

interface Props {
    color?: string
    width?: string
    height?: string
}

const RoundLoader: React.FC<Props> = ({color, width, height}): JSX.Element => {

    return (
        <div className={css.wrapper}>
            <LoaderFreshSVG color={color} width={width} height={height} />
        </div>
    )
}

export default RoundLoader
import React from 'react';
import styles from './Button.module.css'
import { useTranslation } from 'react-i18next';
import RoundLoader from '../../layout/RoundLoader/RoundLoader';

interface Props {
  loading?: boolean
  disabled?: boolean
}

export const Button: React.FC<Props> = ({ loading = false, disabled = false }) => {
  const { t } = useTranslation();

  let buttonStyle = styles.b2bButton;
  if (loading) {
    buttonStyle += ` ${styles.b2bButtonLoading}`
  }
  if (disabled) {
    buttonStyle += ` ${styles.b2bButtonDisabled}`
  }

  return (
    <button className={buttonStyle} disabled={!!disabled}>
      {!loading ? t('submit') : <RoundLoader />}
    </button>
  );
}
export const CGPARCEL_URL = `https://antifraud.cgparcel.net/AntiFraud/Prepare/`;
export const PHONE_TEL_INPUT = `phone-tel-input`;

export const PAYMENT_METHODS = {
  Card: 'Card',
  Phone: 'Phone',
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY',
  applePayLower: 'applepay',
  googlePayLower: 'googlepay',
}

export const PAYMENT_GATEWAYS = {
  ADYEN: 'Adyen',
  STRIPE: 'Stripe',
}

export const PAYMENT_PLANS = {
  daily: 'daily',
  monthly: 'monthly',
  yearly: 'yearly',
  weekly: 'weekly',
  quarterly: 'quarterly',
  semiannually: 'semiannually'
}

export const BROWSER_NAMES = {
  SAFARI: 'Safari',
}

export const GOOGLE_APPLE_PAY_DISPLAY_STATUSES = {
  close: 0,
  open: 1,
}

export const CARD_DISPLAY_STATUSES = {
  close: 0,
  open: 1,
  openManually: 2,
}

export const KSA_ZAIN_OPERATOR = 'KSA-ZAIN'
export const KSA_MOBILY_OPERATOR = 'KSA-MOBILY'
export const KSA_STC_OPERATOR = 'KSA-STC'

export const foxPushDisplayId = 'foxpush_display_1000341'

export const routesThatShowsAd = { 'gam': foxPushDisplayId }

export const CLOUDFRONT_LANDING_PAGE_ROOT_URL = 'https://d2rwz7a7lb9mw2.cloudfront.net/landingpage'
export const CLOUDFRONT_LOGOS_ROOT_URL = 'https://d2rwz7a7lb9mw2.cloudfront.net/logos'
export const CLOUDFRONT_GOVIP_ROOT_URL = 'https://d2rwz7a7lb9mw2.cloudfront.net/govip'

export const APP_THEMES = {
  WHITE: 'white',
  BLACK: 'black',
  BLUE: 'blue',
}

export const WHITE_THEME_CLASS_NAME = 'white-version'
export const BLUE_THEME_CLASS_NAME = 'blue-version'

export const withoutAnalytics = true

export const ENCRYPTED_ADYEN_BILLING_PLANS = {
  monthly: {
    encryptedBillingPlan: 'eyJiaWxsaW5nT3B0aW9uIjoibW9udGhseSIsInByaWNlIjo4LCJ0cmlhbER1cmF0aW9uIjowLCJhbGxvd0FsbFVzZXJzVG9IYXZlVHJpYWwiOmZhbHNlfQ==',
    amount: 800,
  },
  quarterly: {
    encryptedBillingPlan: 'eyJiaWxsaW5nT3B0aW9uIjoicXVhcnRlcmx5IiwicHJpY2UiOjI0LCJ0cmlhbER1cmF0aW9uIjowLCJhbGxvd0FsbFVzZXJzVG9IYXZlVHJpYWwiOmZhbHNlfQ==',
    amount: 2400,
  },
  semianually: {
    encryptedBillingPlan: 'eyJiaWxsaW5nT3B0aW9uIjoic2VtaWFudWFsbHkiLCJwcmljZSI6MzgsInRyaWFsRHVyYXRpb24iOjAsImFsbG93QWxsVXNlcnNUb0hhdmVUcmlhbCI6ZmFsc2V9',
    amount: 3800,
  },
  yearly: {
    encryptedBillingPlan: 'eyJiaWxsaW5nT3B0aW9uIjoieWVhcmx5IiwicHJpY2UiOjYwLCJ0cmlhbER1cmF0aW9uIjowLCJhbGxvd0FsbFVzZXJzVG9IYXZlVHJpYWwiOmZhbHNlfQ==',
    amount: 6000,
  },
}

export const SERVER_STATUS_CODE_RESPONSES = {
  USER_ALREADY_SUBSCRIBED: 10132,
  ADDITIONAL_ADCTION_REQUIRED: 10102,
}

export const LANDING_PAGE_VIEWS = {
  MAIN: 'main',
  PHONE_NUMBER: 'phoneNumber',
};

export const VARIATION_KEYS = {
  v3: 'v3',
  v4: 'v4',
  v5: 'v5',
}

export const DVPASS_DCB_PROTECT_CHALLENGE_NAME = 'dvpassDCBProtectChallenge'

export const LAYOUT_TYPES = {
  TV: 'tv'
}

export const UTM_ID_QUERY_KEY = "utm_id";
export const initiative_utm_id = "initiative";

export const USER_ALREADY_SUBSCRIBED_UNIFIED_RESPONSE_CODE = '10132';

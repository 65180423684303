import React, { useState } from "react";

interface ISubscribtionContext {
    phone: string
    setPhone: React.Dispatch<React.SetStateAction<string>>
    selectedCountry: string
    setSelectedCountry: React.Dispatch<React.SetStateAction<string>>
    paymentRequest: any
    setPaymentRequest: React.Dispatch<React.SetStateAction<any>>
    token: string
    setToken: React.Dispatch<React.SetStateAction<string>>
    clientSecret: string
    setClientSecret: React.Dispatch<React.SetStateAction<string>>
    intentData: any
    setIntentData: React.Dispatch<React.SetStateAction<any>>
    paymentRequestLoaded: boolean
    setPaymentRequestLoaded: React.Dispatch<React.SetStateAction<boolean>>
    paymentButtonAvailable: boolean,
    setPaymentButtonAvailable: React.Dispatch<React.SetStateAction<boolean>>
}

export const SubscribtionContext = React.createContext<ISubscribtionContext>({
    phone: '',
    setPhone: () => {},
    selectedCountry: '',
    setSelectedCountry: () => {},
    paymentRequest: null,
    setPaymentRequest: () => {},
    token: '',
    setToken: () => {},
    clientSecret: '',
    setClientSecret: () => {},
    intentData: null,
    setIntentData: () => {},
    paymentRequestLoaded: false,
    setPaymentRequestLoaded: () => {},
    paymentButtonAvailable: true,
    setPaymentButtonAvailable: () => {}
});

interface Props {
    children: React.ReactNode
}

const useSubscribtionContext = () => {
    const [phone, setPhone] = useState('')
    const [selectedCountry, setSelectedCountry] = useState('')

    const [paymentRequest, setPaymentRequest] = useState(null)
    const [token, setToken] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [intentData, setIntentData] = useState(null);
    const [paymentRequestLoaded, setPaymentRequestLoaded] = useState(false)
    const [paymentButtonAvailable, setPaymentButtonAvailable] = useState(true)

    return {
      phone,
      setPhone,
      selectedCountry,
      setSelectedCountry,
      paymentRequest,
      setPaymentRequest,
      token,
      setToken,
      clientSecret,
      setClientSecret,
      intentData,
      setIntentData,
      paymentRequestLoaded,
      setPaymentRequestLoaded,
      paymentButtonAvailable,
      setPaymentButtonAvailable
    }
}

export const SubscribtionContextProvider: React.FC<Props> = ({ children }) => {
    const subscribtionContextValue = useSubscribtionContext()

    return <SubscribtionContext.Provider value={subscribtionContextValue}>
        {children}
    </SubscribtionContext.Provider>
};
export interface SmsInstantRedirectConfigItem {
  name: string;
  shortCode: string;
  body: string;
}

export const smsInstantRedirectConfig: SmsInstantRedirectConfigItem[] = [
  // KSA-STC
  {
    name: 'sms-ksa-stc-daily',
    shortCode: '809989',
    body: '1',
  },
  {
    name: 'sms-ksa-stc-daily-v2',
    shortCode: '809989',
    body: '5',
  },
  {
    name: 'sms-ksa-stc-weekly',
    shortCode: '809989',
    body: '2',
  },
  {
    name: 'sms-ksa-stc-weekly-v2',
    shortCode: '809989',
    body: '4',
  },
  {
    name: 'sms-ksa-stc-monthly',
    shortCode: '809989',
    body: '3',
  },
  // KSA-MOBILY
  {
    name: 'sms-ksa-mobily-daily',
    shortCode: '600300',
    body: '24',
  },
  {
    name: 'sms-ksa-mobily-weekly',
    shortCode: '600300',
    body: '1',
  },
  {
    name: 'sms-ksa-mobily-weekly-7-days-trial',
    shortCode: '600300',
    body: '111',
  },
  {
    name: 'sms-ksa-mobily-weekly-v2',
    shortCode: '600300',
    body: '23',
  },
  {
    name: 'sms-ksa-mobily-monthly',
    shortCode: '600300',
    body: '22',
  },
  // KSA-VIRGIN
  {
    name: 'sms-ksa-virgin-daily',
    shortCode: '300382',
    body: '5',
  },
  {
    name: 'sms-ksa-virgin-weekly',
    shortCode: '300382',
    body: '2',
  },
  {
    name: 'sms-ksa-virgin-monthly',
    shortCode: '300382',
    body: '3',
  },
  // KSA-ZAIN
  {
    name: 'sms-ksa-zain-weekly',
    shortCode: '708900',
    body: 'SUB360W',
  },
  {
    name: 'sms-ksa-zain-monthly',
    shortCode: '708900',
    body: 'SUB3M',
  },
];

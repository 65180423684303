import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Routes from './components/routing/Routes';
import './styles/fonts.css';

import 'bootstrap/dist/css/bootstrap.min.css'; // if possible tree shake
import './styles/App.css';
import './styles/App2.css';
import './styles/AppResponsive.css';
import { SubscribtionContextProvider } from './store/context/SubscribtionContext';
import { AppLoadingContextProvider } from './store/context/AppLoadingContext/AppLoadingContext';
import AppParent from './components/layout/AppParent';
import { useEffect } from 'react';
import { setInappRedirect } from './store/dcb';
import { useDispatch } from 'react-redux';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname.includes('/inappredirect/')) {
      dispatch(setInappRedirect(true));
    }
  }, [dispatch]);

  return (
    <SubscribtionContextProvider>
      <Router basename="/">
        <Fragment>
          <Switch>
            <AppParent>
              <AppLoadingContextProvider>
                <Route component={Routes} />
              </AppLoadingContextProvider>
            </AppParent>
          </Switch>
        </Fragment>
      </Router>
    </SubscribtionContextProvider>
  );
}

export default App;

import { API_SOURCE_WEB_INITIATIVE } from '../constants/apiSources';

const conversionImgId = 'convImgId';
const purchaseImgId = 'purchaseImgId';

const conversionScriptId = 'convScriptId';
const purchaseScriptId = 'purchaseScriptId';

export const DC_LOCAL_API_SOURCE_NAME = 'dc_api_source';

export const PRICE_DC_SS_NAME = 'dc_price_value';
export const ORDER_ID_DC_SS_NAME = 'dc_order_id_value';

export const shouldRunDCEvent = (apiSource: string) =>
  apiSource.toUpperCase() === API_SOURCE_WEB_INITIATIVE.toUpperCase();

const appendImgNode = (src: string, id: string) => {
  const noScriptNode = document.createElement('noscript');

  const imgNode = document.createElement('img');
  imgNode.src = src;
  imgNode.width = 1;
  imgNode.height = 1;
  imgNode.alt = '';

  noScriptNode.id = id;
  noScriptNode.appendChild(imgNode);

  document.body.appendChild(noScriptNode);
};

const removeNodeById = (id: string) => {
  const node = document.getElementById(id);

  if (!node) return;

  node.remove();
};

export const appendPurchaseImg = (priceRevenue: string, orderId: string) => {
  const imgSrc =
    `https://ad.doubleclick.net/ddm/activity/src=9157623;type=vuz_c0;cat=vuzco0;qty=1;cost=${priceRevenue};dc_lat=;dc_rdid=;` +
    // eslint-disable-next-line no-template-curly-in-string
    'tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};' +
    `ord=${orderId}?`;

  appendImgNode(imgSrc, purchaseImgId);
};

export const appendConversionImg = () => {
  const imgSrc =
    // eslint-disable-next-line no-template-curly-in-string
    'https://ad.doubleclick.net/ddm/activity/src=9157623;type=vuz_r0;cat=vuzfp0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?';

  appendImgNode(imgSrc, conversionImgId);
};

export const removeConversionImg = () => removeNodeById(conversionImgId);

const appendEventScript = (textScript: string, id: string) => {
  const script = document.createElement('script');
  script.textContent = textScript;
  script.type = 'text/javascript';
  script.id = id;

  document.body.appendChild(script);
};

export const appendConversionScript = () => {
  const scriptText =
    "gtag('event', 'conversion', { 'allow_custom_scripts': true, 'send_to': 'DC-9157623/vuz_r0/vuzfp0+standard'});";

  appendEventScript(scriptText, conversionScriptId);
};

export const removeConversionScript = () => removeNodeById(conversionScriptId);

export const appendPurchaseScript = (value: string, transaction_id: string) => {
  const scriptText = `gtag('event', 'purchase', {'allow_custom_scripts': true, 'value': ${value}, 'transaction_id': ${transaction_id}, 'send_to': 'DC-9157623/vuz_c0/vuzco0+transactions'});`;

  appendEventScript(scriptText, purchaseScriptId);
};

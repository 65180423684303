import React from 'react';

export const ErrorIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#FB024E"/>
      <path d="M16.7988 7.20154L7.20112 16.7992" stroke="white" strokeWidth="2.4"/>
      <path d="M16.7988 16.7985L7.20112 7.20075" stroke="white" strokeWidth="2.4"/>
    </svg>
  );
}
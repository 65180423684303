import React  from 'react';
import styles from './MainB2BLayout.module.css'
import { VideoBanner } from '../VideoBanner/VideoBanner';
import { B2BForm } from '../B2BForm/B2BForm';
import { useLanguage } from '../../../hooks/useLanguage';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const MainB2BLayout: React.FC = () => {
  useLanguage();

  return (
    <div className={styles.mainLayout} dir={'ltr'}>
      <VideoBanner />
      <GoogleReCaptchaProvider reCaptchaKey={'6LdFyYwpAAAAACC1_D_VPix53BuzzTB7y2nempM_'}>
        <B2BForm />
      </GoogleReCaptchaProvider>
    </div>
  );
};

import { Analytics } from '@firebase/analytics';
import { FirebaseApp } from '@firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { IOperator } from '../Interfaces/CommonInterfaces';

// todo should go to .env
export const firebaseConfig = {
    apiKey: 'AIzaSyA1i4IYzTMuIJRO5ojZxDOT_5Wss4Ky8dE',
    authDomain: 'vuz-app-production.firebaseapp.com',
    databaseURL: 'https://vuz-app-production.firebaseio.com',
    projectId: 'vuz-app-production',
    storageBucket: 'vuz-app-production.appspot.com',
    messagingSenderId: '424628819532',
    appId: '1:424628819532:web:f99a6863786fefb171c6e9',
    measurementId: 'G-NPZXEM7GMZ',
};

export const EVENT_NAMES = {
    dcb_enter_otp: 'dcb_enter_otp',
    dcb_click_button_verify: 'dcb_click_button_verify',
    dcb_click_button_resend_otp: 'dcb_click_button_resend_otp',
    dcb_finish_pin_flow: 'dcb_finish_pin_flow',
    cc_select_email: 'cc_select_email',
    paypal: 'paypal',
    dcb_select_operator: 'dcb_select_operator',
    cc_click_button_paypal_continue: 'cc_click_button_paypal_continue',
    main_click_button_language_option: 'main_click_button_language_option',
    cc_click_button_subscribe: 'cc_click_button_subscribe',
    cc_set_google_pay_payment: 'cc_set_google_pay_payment',
    cc_finish_flow: 'cc_finish_flow',
    cc_set_apple_pay_payment: 'cc_set_apple_pay_payment',
    dcb_redirect_to_app: 'dcb_redirect_to_app',
    dcb_click_button_subscribe: 'dcb_click_button_subscribe',
    dcb_otp_flow: 'dcb_otp_flow',
    dcb_select_msisdn: 'dcb_select_msisdn',
    main_click_button_unsubscribe_cc: 'main_click_button_unsubscribe_cc',
    cc_select_info: 'cc_select_info',
    main_click_button_by_card: 'main_click_button_by_card',
    main_click_button_language_dropdown: 'main_click_button_language_dropdown',
    dcb_redirect_to_cc: 'dcb_redirect_to_cc',
    main_click_button_exit_page: 'main_click_button_exit_page',
    cc_click_paypal_button: 'cc_click_paypal_button',
    cc_click_apple_pay_button: 'cc_click_apple_pay_button',
    cc_redirect_apple_pay_landing_page: 'cc_redirect_apple_pay_landing_page',
    cc_click_google_pay_button: 'cc_click_google_pay_button',
    main_click_button_main_website: 'main_click_button_main_website',
    main_click_button_privacy_policy: 'main_click_button_privacy_policy',
    main_click_button_terms_and_conditions: 'main_click_button_terms_and_conditions',
    main_click_button_community_guidelines: 'main_click_button_community_guidelines',
    dcb_hosted_flow: 'dcb_hosted_flow',
    dcb_finish_hosted_flow: 'dcb_finish_hosted_flow',
    dcb_failed_hosted_flow: 'dcb_failed_hosted_flow',
    cc_start_flow: 'cc_start_flow',
    dcb_start_flow: 'dcb_start_flow',
    cc_finish_flow_paypal: 'cc_finish_flow_paypal',
    cc_redirect_to_app: 'cc_redirect_to_app',
    cc_failed_flow_paypal: 'cc_failed_flow_paypal',
    cc_failed_flow: 'cc_failed_flow',
    cc_set_3ds_payment: 'cc_set_3ds_payment',
};

// could be singleton but do not see reason, for now I keep all static and with one initializer
export class LogService {
    static analytics: Analytics;

    constructor() {
        LogService.analytics = getAnalytics(this.getApp());
    }

    init(force = false) {
        if (!LogService.analytics || force) {
            LogService.analytics = getAnalytics(this.getApp());
        }
    }

    private getApp(): FirebaseApp {
        return initializeApp(firebaseConfig);
    }

    public static handleEvent(eventName: string, additionalParams = {}) {
        const date = new Date();
        logEvent(
            LogService.analytics,
            eventName,
            { createdAt: date.toUTCString(), ...additionalParams}
        );
    }

    public static dcbEnterOtpEvent(code: string) {
        LogService.handleEvent(EVENT_NAMES.dcb_enter_otp, { otp: code });
    }

    public static dcbClickButtonVerify(code: string) {
        LogService.handleEvent(EVENT_NAMES.dcb_click_button_verify, { otp: code });
    }

    public static dcbClickButtonResend(code: string) {
        LogService.handleEvent(EVENT_NAMES.dcb_click_button_resend_otp, { otp: code });
    }

    public static dcbFinishPinFlow() {
        setUserProperties(
            LogService.analytics,
            { state: 'subscriber' }
        );

        LogService.handleEvent(EVENT_NAMES.dcb_finish_pin_flow);
    }

    public static dcbFinishHostedFlow() {
        setUserProperties(
            LogService.analytics,
            { state: 'subscriber' }
        );

        LogService.handleEvent(EVENT_NAMES.dcb_finish_hosted_flow);
    }

    public static dcbFailedHostedFlow() {
        setUserProperties(
            LogService.analytics,
            { state: 'otp' }
        );

        LogService.handleEvent(EVENT_NAMES.dcb_failed_hosted_flow);
    }

    public static ccSelectEmail(email: string, paymentGateway: string) {
        LogService.handleEvent(EVENT_NAMES.cc_select_email, { email, paymentGateway });
    }

    public static paypal(userProperties = {}, isVerified = false) {
        setUserProperties(LogService.analytics, { ...userProperties});

        LogService.handleEvent(EVENT_NAMES.paypal, { isVerified });
    }

    public static clickButtonSubscribe(userProperties = {}, isVerified = false, finishFlow = false) {
        setUserProperties(LogService.analytics, { ...userProperties });

        LogService.handleEvent(EVENT_NAMES.cc_click_button_subscribe, { isVerified });

        if (finishFlow) {
            LogService.handleEvent(EVENT_NAMES.cc_finish_flow);
        }
    }

    public static dcbSelectOperator(operator: IOperator) {
        LogService.handleEvent(EVENT_NAMES.dcb_select_operator, { operator });
    }

    public static ccClickButtonPaypalContinue(additionalParams = {}) {
        LogService.handleEvent(EVENT_NAMES.cc_click_button_paypal_continue, additionalParams);
    }

    public static mainClickButtonLanguageOption(selectedLanguage: string) {
        LogService.handleEvent(EVENT_NAMES.main_click_button_language_option, { selectedLanguage });
    }

    public static ccSetGooglePayPayment(userProperties = {}, paymentGateway = "", isVerified = false, finishFlow = false) {
        setUserProperties(LogService.analytics, { ...userProperties});

        LogService.handleEvent(EVENT_NAMES.cc_set_google_pay_payment, { isVerified, paymentGateway });

        if (finishFlow) {
            LogService.handleEvent(EVENT_NAMES.cc_finish_flow);
        }
    }

    public static ccSetApplePayPayment(userProperties = {}, paymentGateway = "", isVerified = false, finishFlow = false) {
        setUserProperties(LogService.analytics, { ...userProperties });

        LogService.handleEvent(EVENT_NAMES.cc_set_apple_pay_payment, { isVerified, paymentGateway });

        if (finishFlow) {
            LogService.handleEvent(EVENT_NAMES.cc_finish_flow);
        }
    }

    public static dcbSelectMsisdn(msisdn: string) {
        LogService.handleEvent(EVENT_NAMES.dcb_select_msisdn, { msisdn });
    }

    public static dcbRedirectToApp(redirectUrl: string | string[]) {
        LogService.handleEvent(EVENT_NAMES.dcb_redirect_to_app, { redirectUrl });
    }

    public static dcbClickButtonSubscribe(userProperties = {}) {
        setUserProperties(LogService.analytics, { ...userProperties, state: 'msisdn' });
        LogService.handleEvent(EVENT_NAMES.dcb_click_button_subscribe);
    }

    public static dcbOtpFlow(userProperties = {}) {
        setUserProperties(LogService.analytics, { ...userProperties, state: 'otp' });
        LogService.handleEvent(EVENT_NAMES.dcb_otp_flow);
    }

    public static dcbHostedFlow(userProperties = {}, additionalParams = {}) {
        setUserProperties(LogService.analytics, userProperties);
        LogService.handleEvent(EVENT_NAMES.dcb_hosted_flow, additionalParams);
    }

    public static ccStartFlow(userProperties = {}, additionalParams = {}) {
        setUserProperties(LogService.analytics, userProperties);
        LogService.handleEvent(EVENT_NAMES.cc_start_flow, additionalParams);
    }

    public static dcbStartFlow(userProperties = {}) {
        setUserProperties(LogService.analytics, userProperties);
        LogService.handleEvent(EVENT_NAMES.dcb_start_flow);
    }

    public static finishFlowPaypal(userProperties = {}) {
        setUserProperties(LogService.analytics, userProperties);
        LogService.handleEvent(EVENT_NAMES.cc_finish_flow_paypal);
    }

    public static redirectToApp(userProperties = {}, redirectURL = '', finishFlow = false) {
        setUserProperties(LogService.analytics, userProperties);
        LogService.handleEvent(EVENT_NAMES.cc_redirect_to_app, { redirectURL });

        if (finishFlow) {
            LogService.handleEvent(EVENT_NAMES.cc_finish_flow);
        }
    }

    public static languageChangedManually(selectedLanguage: string) {
        LogService.handleEvent(EVENT_NAMES.main_click_button_language_dropdown)
        LogService.mainClickButtonLanguageOption(selectedLanguage)
    }
}

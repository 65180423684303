import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { WEB_LOOKUP, WEB_NEW_LOOKUP } from '../../constants/apiSources';
import { HRLLookupBanner } from '../configs/modules/banners';
import { smsInstantRedirectConfig } from '../configs/smsInstantRedirectConfig';
import DCBSms from '../views/DCBSms/DCBSms';
import { MainB2BLayout } from '../B2B';

const DCB = React.lazy(() => import('../views/DCB/DCB'));
const WelcomeParent = React.lazy(() => import('../views/Welcome/WelcomeParent'));
const ThankYouParent = React.lazy(() => import('../views/Thankyou/ThankYouParent'));
const ConfirmPin = React.lazy(() => import('../views/ConfirmPin/ConfirmPin'));
const Unsubscribe = React.lazy(() => import('../views/Unsubscribe'));
const BeginUnsubscribe = React.lazy(() => import('../views/BeginUnsubscribe'));
const NotFound = React.lazy(() => import('../views/NotFound'));
const Voucher = React.lazy(() => import('../views/Voucher'));
const VoucherPin = React.lazy(() => import('../views/VoucherPin'));
const WelcomeVoucher = React.lazy(() => import('../views/WelcomeVoucher'));
const AppDownload = React.lazy(() => import('../views/AppDownload'));
const RoutesConfigurations = React.lazy(() => import('./RoutesConfigurations'));
const ThreeDSAdyenRedirect = React.lazy(() => import('../subscription/ThreeDSAdyenRedirect'));

const Routes = () => {
  return (
    <section className="page-section" style={{ minHeight: '100vh' }}>
      <Switch>
        <Route exact path="/appdownload/:token" component={AppDownload} />

        <Route
          exact
          path="/hlrlookup/new"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_NEW_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/new/:lang"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_NEW_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/new/:lang/:country"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_NEW_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/new/:lang/:country/:operator"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_NEW_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/new/:lang/:country/:operator/:msisdn"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_NEW_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />

        <Route
          exact
          path="/hlrlookup"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/:lang"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/:lang/:country"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/:lang/:country/:operator"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />
        <Route
          exact
          path="/hlrlookup/:lang/:country/:operator/:msisdn"
          render={(props) => (
            <DCB
              {...props}
              apiSource={WEB_LOOKUP}
              phoneFormConfig={{ renderOperator: false, telInput: true, isHlrlookup: true }}
              bannerConfig={HRLLookupBanner}
            />
          )}
        />

        <Route exact path="/voucher" component={Voucher} />
        <Route exact path="/voucher/pin" component={VoucherPin} />
        <Route exact path="/voucher/congrats" component={WelcomeVoucher} />

        <Route exact path="/unsubscribe" component={Unsubscribe} />
        <Route exact path="/cc/unsubscribe" component={Unsubscribe} />

        <Route exact path="/confirm-pin" component={ConfirmPin} />
        <Route exact path="/cc/welcome" component={WelcomeParent} />

        <Route exact path="/thankyou" component={ThankYouParent} />
        <Route exact path="/thankyou/:sessionId" component={ThankYouParent} />
        <Route exact path="/thankyou/:lang/:sessionId" component={ThankYouParent} />

        <Route exact path="/subscribers/inapp/unsubscribe/begin" component={BeginUnsubscribe} />

        {smsInstantRedirectConfig.map((el) => {
          return (
            <Route
              key={el.name}
              exact
              path={`/${el.name}`}
              render={(props) => (
                <DCBSms {...props} name={el.name} shortCode={el.shortCode} body={el.body} />
              )}
            />
          );
        })}

        {['/b2b', '/b2b/:lang'].map((path) => (
          <Route exact key={path} path={path} render={(props) => <MainB2BLayout {...props} />} />
        ))}

        <Route exact path="/adyenredirect" component={ThreeDSAdyenRedirect} />
        <RoutesConfigurations />
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;

import { isAE, isIQ, isQA } from '../constants/country';

const Helpers = {
  getAxiosHeadersConfig: () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return config;
  },
  getCountryPlaceholderOrig: (countryCode) => {
    // todo Artur, make this as a object key country -> value placeholder
    let placeholder = '05X XXX XXXX';
    if (isAE(countryCode)) {
      placeholder = '05X XXX XXXX';
    } else if (countryCode == 'eg' || countryCode == 'EG') {
      placeholder = '0100 XXX XXXX';
    } else if (countryCode == 'bh' || countryCode == 'BH') {
      placeholder = '3600 XXXX';
    } else if (isQA(countryCode)) {
      placeholder = '3312 XXXX';
    } else if (countryCode == 'jo' || countryCode == 'JO') {
      placeholder = '07 9012 XXXX';
    } else if (countryCode == 'tn' || countryCode == 'TN') {
      placeholder = '20 XXX XXX';
    } else if (isIQ(countryCode)) {
      placeholder = '0791 XXX XXXX';
    } else if (countryCode == 'ps' || countryCode == 'PS') {
      placeholder = '0599 XXX XXX';
    } else if (countryCode == 'il' || countryCode == 'IL') {
      placeholder = '0599 XXX XXX';
    } else if (countryCode == 'sa' || countryCode == 'SA') {
      placeholder = '05X XXX XXXX';
    } else if (countryCode == 'kw' || countryCode == 'KW') {
      placeholder = '500 XXXXX';
    } else if (countryCode == 'za' || countryCode == 'ZA') {
      placeholder = '071 XXX XXXX';
    } else if (countryCode == 'es' || countryCode == 'ES') {
      placeholder = '612 34 56 78';
    } else if (countryCode == 'ca' || countryCode == 'CA') {
      placeholder = '(506) XXX-XXXX';
    } else if (countryCode == 'uk' || countryCode == 'UK') {
      placeholder = '07400 XXXXXX';
    } else if (countryCode == 'id' || countryCode == 'ID') {
      placeholder = '0812-XXX-XXX';
    } else if (countryCode == 'my' || countryCode == 'MY') {
      placeholder = '012-XXX XXXX';
    } else if (countryCode == 'sg' || countryCode == 'SG') {
      placeholder = '08XXXXXXX';
    }

    return placeholder;
  },

  getCountryPlaceholder: (countryCode, lang = '') => {
    let placeholder = '05XXXXXXXX';
    // todo Artur, make this as a object key country -> value placeholder
    if (isAE(countryCode)) {
      placeholder = '05XXXXXXXX';
    } else if (countryCode == 'eg' || countryCode == 'EG') {
      placeholder = '01XXXXXXXXX';
    } else if (countryCode == 'bh' || countryCode == 'BH') {
      placeholder = '36XXXXXX';
    } else if (isQA(countryCode)) {
      placeholder = 'XXXXXXXX';
    } else if (countryCode == 'jo' || countryCode == 'JO') {
      placeholder = '07XXXXXXXX';
    } else if (countryCode == 'tn' || countryCode == 'TN') {
      placeholder = '20XXXXXX';
    } else if (isIQ(countryCode)) {
      placeholder = '07XXXXXXXXX';
    } else if (countryCode == 'ps' || countryCode == 'PS') {
      placeholder = '05XXXXXXXX';
    } else if (countryCode == 'il' || countryCode == 'IL') {
      placeholder = '05XXXXXXXX';
    } else if (countryCode == 'sa' || countryCode == 'SA') {
      placeholder = '05XXXXXXXX';
    } else if (countryCode == 'kw' || countryCode == 'KW') {
      placeholder = '5XXXXXXX';
    } else if (countryCode == 'za' || countryCode == 'ZA') {
      placeholder = '071XXXXXXX';
    } else if (countryCode == 'es' || countryCode == 'ES') {
      placeholder = '612XXXXXX';
    } else if (countryCode == 'ca' || countryCode == 'CA') {
      placeholder = '506XXXXXXX';
    } else if (countryCode == 'uk' || countryCode == 'UK') {
      placeholder = '07400XXXXXX';
    } else if (countryCode == 'id' || countryCode == 'ID') {
      placeholder = '08XXXXXXXX';
    } else if (countryCode == 'my' || countryCode == 'MY') {
      placeholder = '01XXXXXXXX';
    } else if (countryCode == 'pk' || countryCode == 'PK') {
      placeholder = '03XXXXXXXXX  ';
    } else if (countryCode == 'sg' || countryCode == 'SG') {
      placeholder = '08XXXXXXX';
    } else if (lang == 'en') {
      placeholder = 'Mobile number';
    } else if (lang == 'ar') {
      placeholder = 'رقم الجوال';
    } else if (countryCode == 'ma' || countryCode == 'MA') {
      placeholder = '6XXXXXXX';
    } 

    return placeholder;
  },
  getUrlParameter: (name) => {
    // eslint-disable-next-line
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  },
  isSafari: () => {
    if (navigator?.userAgentData?.brands) {
      return navigator.userAgentData.brands.some(brand => brand.brand.toLowerCase().includes('safari'));
    }
    // If navigator.userAgentData is not available, use we use fallback
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  isEmail: (text) => {
    // we do not support non english characters
    const re =
      /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi;

    return String(text).toLowerCase().match(re);
  },
  isPhoneNumberValidSpecialCases: (phoneNumber) => {
    const JordanRegExp1 = /^00\d{3}\d{3}$/; // 00 xxx xxx
    const JordanRegExp2 = /^0[1-9](?!7)(?!9)\d{3}\d{3}$/; // 0x xxx xxx and first xx must not be 79
    const JordanRegExp3 = /^000\d{2}\d{3}$/; // 00 0xx xxx

    const MoroccoRegExp = /^212\d{9}$/

    return JordanRegExp1.test(phoneNumber) || JordanRegExp2.test(phoneNumber) || JordanRegExp3.test(phoneNumber) || MoroccoRegExp.test(phoneNumber)
  },
};

export default Helpers;

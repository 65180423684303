import axios from 'axios';
import { setAlert } from './alert';
import Helpers from '../utils/Helpers';
import Language from '../constants/Language';
import TagManager from 'react-gtm-module';
import Config from '../utils/Config';
import { PAYMENT_GATEWAYS, PAYMENT_METHODS } from '../constants/app';
import { LogService } from '../services/LogService';
import { decryptAffiliate } from './utils/decryptAffiliate';

// TYPES

const CHANGE_PAYMENT_METHOD = 'subscription/changePaymentMethod';
const FORM_WAS_SUBMITTED = 'subscription/formWasSubmitted';
const FORM_RESULT_RETRIEVED = 'subscription/formResultRetrieved';
const NEED_TO_RESET_FORM = 'subscription/needToResetForm';
const FORM_RESET_COMPLETE = 'subscription/formResetComplete';
const GPAY_RECEIVED = 'subscription/gpayReceived';
const GPAY_LOADED = 'subscription/gpayLoaded';
const SET_VOUCHER = 'subscription/setVoucher';
// const API_URL = `https://prod-plus-api.360vuz.com`;
const API_URL = Config.API_URL;

const VOUCHER_SUBSCRIBE_RECEIVED = 'subscription/voucherSuccess';
const VOUCHER_RESPONSE_RECEIVED_WITH_ERROR = 'subscription/voucherError';
const VOUCHER_SUBSCRIBE2_RECEIVED = 'subscription/voucherSuccess2';
const VOUCHER_RESPONSE2_RECEIVED_WITH_ERROR = 'subscription/voucherError2';
const VOUCHER_REST_TIMER_ACTIVE = 'subscription/voucherResetTimerActive';

const VOUCHER_RESEND_OTP_REQUEST_SENT = 'subscription/voucherResendOtpRequestSent';
const VOUCHER_RESEND_OTP_RESULT_RECEIVED = 'subscription/voucherResendOtpResultReceived';
const VOUCHER_RESEND_OTP_RESULT_RECEIVED_WITH_ERROR =
  'subscription/voucherResendOtpResultReceivedWithError';

// INITIAL STATE

const initialState = {
  paymentMethod: PAYMENT_METHODS.Phone,
  viewloading: false,
  btnLoading: false,
  error: {},
  needToResetForm: false,
  applePay: {},
  gpayReceived: false,
  gpayLoading: false,
  voucher: {},
  voucherRes: {},
  timerActive: true,
  timerCount: 0,
};

// REDUCER

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload,
      };
    case FORM_WAS_SUBMITTED:
      return {
        ...state,
        btnLoading: true,
      };
    case FORM_RESULT_RETRIEVED:
      return {
        ...state,
        btnLoading: false,
      };
    case NEED_TO_RESET_FORM:
      return {
        ...state,
        btnLoading: false,
        needToResetForm: true,
      };
    case FORM_RESET_COMPLETE:
      return {
        ...state,
        needToResetForm: false,
      };
    case GPAY_RECEIVED:
      return {
        ...state,
        gpayReceived: true,
        gpayLoading: true,
      };
    case GPAY_LOADED:
      return {
        ...state,
        gpayLoading: false,
      };
    case SET_VOUCHER:
      return {
        ...state,
        voucher: payload,
      };
    case VOUCHER_SUBSCRIBE_RECEIVED:
      return {
        ...state,
        btnLoading: false,
        voucherRes: payload,
      };
    case VOUCHER_RESPONSE_RECEIVED_WITH_ERROR:
      return {
        ...state,
        btnLoading: false,
      };

    case VOUCHER_RESPONSE2_RECEIVED_WITH_ERROR:
      return {
        ...state,
        btnLoading: false,
      };

    case VOUCHER_REST_TIMER_ACTIVE:
      return {
        ...state,
        timerActive: true,
      };

    case VOUCHER_RESEND_OTP_REQUEST_SENT:
      return {
        ...state,
        // btnLoading: true,
        timerActive: false,
        timerCount: state.timerCount + 1,
      };
    case VOUCHER_RESEND_OTP_RESULT_RECEIVED:
      return {
        ...state,
        // btnLoading: false,
        // resendOtpData: payload,
      };
    case VOUCHER_RESEND_OTP_RESULT_RECEIVED_WITH_ERROR:
      return {
        ...state,
        // btnLoading: false,
        error: payload,
      };
    default:
      return state;
  }
}

// ACTIONS

export const changePaymentMethod = (paymentMethod) => async (dispatch) => {
  dispatch({
    type: CHANGE_PAYMENT_METHOD,
    payload: paymentMethod,
  });
};

export const formWasSubmitted = () => async (dispatch) => {
  dispatch({
    type: FORM_WAS_SUBMITTED,
    payload: null,
  });
};

export const formResultRetrieved = () => async (dispatch) => {
  dispatch({
    type: FORM_RESULT_RETRIEVED,
    payload: null,
  });
};

export const subscribeUsingCard =
  (formData, history, isTrial = false) =>
  async (dispatch) => {
    let invalid_card_details = Language.en_invalid_cars;
    if (formData.language === 'ar') invalid_card_details = Language.ar_invalid_cars;

    let email_already_subscribed = Language.en_email_subscribed;
    if (formData.language === 'ar') email_already_subscribed = Language.ar_email_subscribed;

    formData.affiliate = '360VUZ';
    // formData.subscriptionOption = 'MONTHLY';
    formData.utmSource = null;
    formData.utmMedium = null;
    formData.utmChannel = null;
    formData.urlReferrer = window.location.href;

    // formData.environment = 'SANDBOX';
    const config = Helpers.getAxiosHeadersConfig();

    try {
      const response = await axios.post(
        `${API_URL}/api/cc/${isTrial ? 'subscribeTrial' : 'subscribe'}`,
        formData,
        config
      );

      let pageLink = window.location.href;

      try {
        let pData = {};
        let pData1 = Helpers.getUrlParameter('data1') || '';
        let pData2 = Helpers.getUrlParameter('data2') || '';
        let pData3 = Helpers.getUrlParameter('data3') || '';

        if (pData1) pData.data1 = pData1;
        if (pData2) pData.data2 = pData2;
        if (pData3) pData.data3 = pData3;

        let porderId = null;
        if (response?.data?.data?.orderId) {
          porderId = response.data.data.orderId;
        }
        if (pageLink.includes('/cc')) {
          // window.papCCleadAction(porderId, pData);
        }
      } catch (err) {}

      console.log(response);

      if (response.data && response.data.data.status && response.data.data.status === 'Pending') {
        window.location = response.data.data._links.redirect.href;
        return;
      }

      if (response.data.data.errorCode !== 0 && response.data.data.errorCode) {
        if (response.data.data.errorSource === 'Checkout') {
          dispatch(setAlert(invalid_card_details, 'danger'));
        } else {
          if (
            response.data.message === 'This email is already subscribed' ||
            response.data.message === 'This email  is already subscribed'
          ) {
            dispatch(setAlert(email_already_subscribed, 'danger'));
          } else {
            dispatch(setAlert(`${response.data.message}`, 'danger'));
          }
        }
        if (formData.payment_source === 'google-pay') {
          LogService.ccSetGooglePayPayment({ email: formData.email, state: 'msisdn' })
        } else if (formData.payment_source === 'apple-pay') {
          LogService.ccSetApplePayPayment({ email: formData.email, state: 'msisdn' })
        } else if (formData.payment_source === 'paypal') {
          LogService.paypal({ email: formData.email, state: 'msisdn' })
        } else {
          LogService.clickButtonSubscribe({ email: formData.email, state: 'msisdn' })
        }
      } else {
        let tagManagerArgs = {
          dataLayer: {
            event: 'subscribe',
            eventCategory: 'CC',
            eventAction: 'subscribe',
            eventValue: '24',
            subscription_plan: 'MONTHLY',
            revenue: '$24',
            currency: 'AED',
            page_version: 'Dark',
          },
          dataLayerName: 'SubscriptionDataLayer',
        };

        tagManagerArgs.dataLayer.subscription_plan = formData.subscriptionOption;
        tagManagerArgs.dataLayer.revenue = formData.subscriptionOption === 'MONTHLY' ? '$8' : '$24';
        tagManagerArgs.dataLayer.page_version = formData.isWhite === true ? 'Light' : 'Dark';
        TagManager.dataLayer(tagManagerArgs);

        let pathname = '/cc/welcome';
        if (formData.isV4) {
          pathname = '/cc/4/welcome';
        }
        try {
          if (localStorage) {
            localStorage.setItem('isPromoValid', 'false');
            localStorage.setItem('appliedPromocode', '');
          }
        } catch (err) {}

        let orderId = null;
        if (response.data?.data?.orderId) {
          orderId = response.data.data.orderId;
        }

        let papData = {};
        let papData1 = Helpers.getUrlParameter('data1') || '';
        let papData2 = Helpers.getUrlParameter('data2') || '';
        let papData3 = Helpers.getUrlParameter('data3') || '';
        let a_aid = Helpers.getUrlParameter('a_aid') || '';

        if (a_aid) {
          const decrypted_a_aid = await decryptAffiliate(a_aid)
          if (decrypted_a_aid) {
            a_aid = decrypted_a_aid
          }
        }

        if (papData1) papData.data1 = papData1;
        if (papData2) papData.data2 = papData2;
        if (papData3) papData.data3 = papData3;
        if (a_aid) papData.affiliateId = a_aid;

        if (pageLink.includes('/cc') && a_aid) {
          window.papCCtrialAction(orderId, papData);
        }

        const userProperties = {
          state: 'subscriber',
          subscriberId: response.data?.data?.subscriberId,
        }

        if (formData.payment_source === 'google-pay') {
          LogService.ccSetGooglePayPayment(userProperties, PAYMENT_GATEWAYS.STRIPE, true, true)
        } else if (formData.payment_source === 'apple-pay') {
          LogService.ccSetApplePayPayment(userProperties, PAYMENT_GATEWAYS.STRIPE, true, true)
        } else if (formData.payment_source === 'paypal') {
          LogService.ccClickButtonPaypalContinue()
        } else {
          LogService.clickButtonSubscribe(userProperties, true, true)
        }

        if (
          !window.location.href.includes('noredirect') &&
          formData.email !== 'cctestx@360mea.com'
        ) {
          history.push({
            pathname: pathname,
            state: {
              activationURL: response.data.data.activationURL,
              isWhite: formData.isWhite,
            },
          });
        }
      }
    } catch (err) {
      console.log(err);

      dispatch(setAlert('Error while processing payment. Please contact support', 'danger'));
      if (formData.payment_source === 'google-pay') {
        LogService.ccSetGooglePayPayment()
      } else if (formData.payment_source === 'apple-pay') {
        LogService.ccSetApplePayPayment();
      } else if (formData.payment_source === 'paypal') {
        LogService.paypal();
      } else {
        LogService.clickButtonSubscribe();
      }
    }

    dispatch({
      type: FORM_RESULT_RETRIEVED,
      payload: null,
    });
  };

export const unsubscribe = (formData) => async (dispatch) => {
  dispatch({
    type: FORM_WAS_SUBMITTED,
    payload: null,
  });
  console.log(formData);
  const config = Helpers.getAxiosHeadersConfig();

  try {
    const res = await axios.post(`${API_URL}/api/cc/unsubscribeCC`, formData, config);

    if (res.data.errorCode !== 0 && res.data.errorCode) {
      dispatch(setAlert(`${res.data.message}`, 'danger'));
    } else {
      dispatch(setAlert(`${res.data.message}`, 'success'));
      dispatch({
        type: NEED_TO_RESET_FORM,
        payload: null,
      });
    }
  } catch (err) {
    console.log(err);

    dispatch(setAlert('Error while unsubscribing. Please contact support', 'danger'));
  }
  dispatch({
    type: FORM_RESULT_RETRIEVED,
    payload: null,
  });
};

export const formResetComplete = () => (dispatch) => {
  dispatch({
    type: FORM_RESET_COMPLETE,
    payload: null,
  });
};

export const gpayReceivedd = () => (dispatch) => {
  dispatch({
    type: GPAY_RECEIVED,
    payload: null,
  });
};

export const gpayLoaded = () => (dispatch) => {
  dispatch({
    type: GPAY_LOADED,
    payload: null,
  });
};

export const initiatePaypalPayment = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  //sandbox
  // const handleInitiatePaypalPaymentUrl = 'http://localhost/api/paypal/initiatePaypalPayment';

  //live
  const handleInitiatePaypalPaymentUrl = `${API_URL}/api/paypal/initiatePaypalPayment`;

  formData.urlReferrer = window.location.href;

  dispatch({
    type: FORM_WAS_SUBMITTED,
    payload: null,
  });
  try {
    const res = await axios.post(handleInitiatePaypalPaymentUrl, formData, config);
    let resData = res.data;
    console.log(resData);
    if (resData.status === 'Pending') {
      let orderId = null;
      if (resData && resData.orderId) {
        orderId = resData.orderId;
      }
      let papData = {};
      let papData1 = Helpers.getUrlParameter('data1') || '';
      let papData2 = Helpers.getUrlParameter('data2') || '';
      let papData3 = Helpers.getUrlParameter('data3') || '';
      let a_aid = Helpers.getUrlParameter('a_aid') || '';

      if (a_aid) {
        const decrypted_a_aid = await decryptAffiliate(a_aid)
        if (decrypted_a_aid) {
          a_aid = decrypted_a_aid
        }
      }

      if (papData1) papData.data1 = papData1;
      if (papData2) papData.data2 = papData2;
      if (papData3) papData.data3 = papData3;
      if (a_aid) papData.affiliateId = a_aid;
      let pageLink = window.location.href;

      if (pageLink.includes('/cc') && a_aid) {
        // window.papCCtrialAction(orderId, papData);
      }
      LogService.ccClickButtonPaypalContinue({ redirectUrl: resData._links.redirect.href })

      window.location.href = resData._links.redirect.href;
    } else {
      dispatch(setAlert('Error while contacting Paypal', 'danger'));

      dispatch({
        type: FORM_RESULT_RETRIEVED,
        payload: null,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch(setAlert('Error while contacting Paypal', 'danger'));

    dispatch({
      type: FORM_RESULT_RETRIEVED,
      payload: null,
    });
  }
};

export const handleVoucherStep1 = (formData, history, isInternalDebugging = false) => async (dispatch) => {
  const config = Helpers.getAxiosHeadersConfig();

  const subscribeRequestBody = {
    mobile: formData.phone,
    email: formData.email,
    countryCode: formData.countryCode,
    voucherCode: formData.voucherCode,
    language: formData.language,
    source: formData.source,
    urlReferrer: window.location.href,
    isDebugging: isInternalDebugging ? 1 : 0,
    // customSms: '8.25 SAR Congrats for subscribing 4444 euro  to VUZ, here\'s your code 7788 for ',
  };

  console.log('handleVoucherStep1 Request', subscribeRequestBody);
  dispatch({
    type: FORM_WAS_SUBMITTED,
    payload: null,
  });

  try {
    const res = await axios.post(`${API_URL}/api/voucher/subscribe`, subscribeRequestBody, config);
    console.log('handleVoucherStep1 response', res.data);
    let { data, statusCode, message } = res.data;

    dispatch({ type: VOUCHER_SUBSCRIBE_RECEIVED, payload: data });

    if (statusCode != 200) {
      dispatch(setAlert(message, 'danger'));
      dispatch({ type: VOUCHER_RESPONSE_RECEIVED_WITH_ERROR, payload: null });
      return;
    }

    history.push('/voucher/pin');
  } catch (err) {
    console.log('handleVoucherStep1error', err);
    dispatch(setAlert('Error processing request. Please contact support', 'danger'));
    dispatch({ type: VOUCHER_RESPONSE_RECEIVED_WITH_ERROR, payload: null });
  }
};

export const handleVoucherStep2 = (formData, voucherData, history, isInternalDebugging = false) => async (dispatch) => {
  const config = Helpers.getAxiosHeadersConfig();

  const subscribeRequestBody = {
    subscriberId: voucherData.subscriberId,
    subscriptionVoucherId: voucherData.subscriptionVoucherId,
    // voucherId: voucherData.voucherId,
    otp: formData.otp,
    language: formData.language,
    isDebugging: isInternalDebugging ? 1 : 0,
    // debugEmails: 'hani.jazzar@360mea.com, Hani Jazzar;',
  };

  console.log('handleVoucherStep2 Request', subscribeRequestBody);
  dispatch({
    type: FORM_WAS_SUBMITTED,
    payload: null,
  });

  try {
    const res = await axios.post(`${API_URL}/api/voucher/verify`, subscribeRequestBody, config);
    console.log('handleVoucherStep2 response', res.data);
    let { data, statusCode, message } = res.data;

    dispatch({ type: VOUCHER_SUBSCRIBE2_RECEIVED, payload: data });

    if (statusCode !== 200) {
      if (formData.language === 'ar') {
        dispatch(setAlert('رقم التعريف غير صالح'));
      } else {
        dispatch(setAlert('Invalid PIN', 'danger'));
      }

      dispatch({ type: VOUCHER_RESPONSE2_RECEIVED_WITH_ERROR, payload: null });
      return;
    }

    history.push({
      pathname: `/voucher/congrats`,
      state: {
        activationURL: data.activationURL,
        billingDurationType: data.billingDurationType,
        billingDuration: data.billingDuration,
      },
    });
  } catch (err) {
    console.log('handleVoucherStep2error', err);
    dispatch(setAlert('Error processing request. Please contact support', 'danger'));
    dispatch({ type: VOUCHER_RESPONSE2_RECEIVED_WITH_ERROR, payload: null });
  }
};

export const handleVoucherResendCode = (formData, voucherData, isInternalDebugging = false) => async (dispatch) => {
  const config = Helpers.getAxiosHeadersConfig();

  const subscribeRequestBody = {
    subscriberId: voucherData.subscriberId,
    subscriptionVoucherId: voucherData.subscriptionVoucherId,
    // voucherId: voucherData.voucherId,
    language: formData.language,
    isDebugging: isInternalDebugging ? 1 : 0,
    // NEED TO INCLUDE CORRECT DEBUG EMAIL IF WE WANT TO USE DEBUG
    // debugEmails: 'hani.jazzar@360mea.com, Hani Jazzar;',
  };

  console.log('handleVoucherResendCode Request', subscribeRequestBody);
  dispatch({
    type: VOUCHER_RESEND_OTP_REQUEST_SENT,
    payload: null,
  });

  try {
    const res = await axios.post(
      `${API_URL}/api/voucher/subscribe/resend`,
      subscribeRequestBody,
      config
    );
    console.log('handleVoucherResendCode response', res.data);
    let { data, statusCode, message } = res.data;

    dispatch({ type: VOUCHER_RESEND_OTP_RESULT_RECEIVED, payload: data });

    if (statusCode != 200) {
      dispatch(setAlert(message, 'danger'));
      return;
    }
  } catch (err) {
    console.log('handleVoucherResendCodeerror', err);
    dispatch(setAlert('Error processing request. Please contact support', 'danger'));
    dispatch({ type: VOUCHER_RESEND_OTP_RESULT_RECEIVED_WITH_ERROR, payload: null });
  }
};

export const resetTimerActive = () => async (dispatch) => {
  dispatch({ type: VOUCHER_REST_TIMER_ACTIVE, payload: null });
};

import React, { useCallback, useEffect, useState } from 'react';

const CAN_USE_DOM = typeof window !== 'undefined';
const MQ = {
  MOBILE: '(max-width: 768px)',
  TABLET: '(max-width: 1024px)',
} as const;

type Query = keyof typeof MQ;

export const useResponsive = (query: Query | Query[]) => {
  const [isInitialRenderPassed, setIsInitialRenderPassed] = useState(false);
  const [isQueryMatching, setIsQueryMatching] = React.useState(isMatching(query));
  const result = isInitialRenderPassed ? isQueryMatching : undefined;

  const isMatchingQuery = useCallback(
    (query: Query | Query[]) => {
      return isInitialRenderPassed && isMatching(query);
    },
    [isInitialRenderPassed]
  );

  const onWindowResize = useCallback(() => {
    setIsQueryMatching(isMatching(query));
  }, [query]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return function cleanup() {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [isQueryMatching, onWindowResize]);

  useEffect(() => {
    setIsInitialRenderPassed(true);
  }, []);

  return [result, isMatchingQuery] as const;
};

const isMatching = (query: Query | Query[]) => {
  let array;

  if (Array.isArray(query)) {
    array = query.map((item) => CAN_USE_DOM && Boolean(window.matchMedia(MQ[item]).matches));
    return array.some((o) => o);
  }

  return CAN_USE_DOM && Boolean(window.matchMedia(MQ[query]).matches);
};

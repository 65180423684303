import axios from 'axios'
import Config from '../utils/Config'

//TYPES

const FETCH_CONFIG = 'FETCH_CONFIG'

//INITIAL STATE

const initialState = {}

//REDUCER

export default function reducer(state = initialState, action) {
    const {type, payload} = action
    switch(type) {
        case FETCH_CONFIG:
            return {...state, [`${payload.name}`]: payload.data }
        default:
            return state
    }
}

//ACTIONS

export const fetchConfig = async (variation) => {
  return await axios.get(`${Config.API_URL}/builder/variations/query?variationURL=${variation}`)
}

export const setConfig = (data, variation) => async (dispatch) => {
  dispatch({
    type: FETCH_CONFIG,
    payload: { data, name: `${variation}` }
  })
}

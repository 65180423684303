import React from 'react';
import styles from './VideoBanner.module.css';
import cn from 'classnames'
import { useTranslation } from 'react-i18next';
import { getDirFromLocale, isRTL } from '../../../utils/general';
import { useAppSelector } from '../../../store/hooks';
import { B2BLanguageDropdown } from '../B2BLanguageDropdown/B2BLanguageDropdown';
import ReactPlayer from 'react-player';

export const VideoBanner: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useAppSelector((state) => state.global);

  return (
    <div className={styles.wrapper} dir={getDirFromLocale(language.locale)}>
      <div className={styles.language_wrapper} dir={'ltr'}>
        <B2BLanguageDropdown />
      </div>
      <h1 className={cn(styles.title, {
        [styles.titleRight]: isRTL(language.direction) 
      })}>
        {t('b2b_banner_title')}
      </h1>
      <ReactPlayer
        url="https://d2rwz7a7lb9mw2.cloudfront.net/landingpage/videos/vuz_on_apple_vision_pro.mp4"
        playing
        loop
        volume={0}
        muted
        playsinline
        width="100%"
        className={styles.videoFrame}
      />
    </div>
  );
};

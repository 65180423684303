import axios from "axios"
import Config from "../../utils/Config"

export const decryptAffiliate = async (affiliate: string): Promise<string | null> => {
  try {
    const res = await axios.get(`${Config.API_URL}/v1/affiliates/decode/name?affiliate=${affiliate}`)
      return res.data.data
    } catch {
      return null
    }
}
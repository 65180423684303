import React from "react";
import { useSelector } from "react-redux";
import {
  isRTL,
  isVariationV3Route,
  isVariationV4Route,
  isVariationV5Route,
  isWhiteDependingOnDOM,
} from '../../utils/general';
import { PAYMENT_METHODS } from '../../constants/app';
import { useLanguage } from '../../hooks/useLanguage';

const ReactPhoneInputCustom = React.lazy(() => import('../../styles/ReactPhoneInputCustom'));
const AppBlue = React.lazy(() => import('../../styles/AppBlue'));
const AppRtl = React.lazy(() => import('../../styles/AppRtl'));
const AppWhite = React.lazy(() => import('../../styles/AppWhite'));
const AppReactTelInput = React.lazy(() => import('../../styles/AppReactTelInput'));

const AppParent = ({ children }) => {
  const { direction, paymentMethod, isV3Route, isV4Route, isV5Route } = useSelector(
    ({ global, subscription, dcb }) => ({
      direction: global.language.direction,
      paymentMethod: subscription.paymentMethod,
      isV3Route: isVariationV3Route(dcb.variationKey),
      isV4Route: isVariationV4Route(dcb.variationKey),
      isV5Route: isVariationV5Route(dcb.variationKey),
    })
  );

  const ignoreCauseItHasItsOwnSetter = window.location.pathname.includes('/b2b');
  useLanguage(ignoreCauseItHasItsOwnSetter);

  return (
    <>
      {isRTL(direction) ? <AppRtl /> : null}
      {isWhiteDependingOnDOM() || isV4Route ? <AppWhite /> : null}
      {isV3Route ? <AppBlue /> : null}
      {paymentMethod === PAYMENT_METHODS.Phone ||
      window.location.pathname.includes('voucher') ||
      window.location.pathname.includes('raffledraw') ? (
        <AppReactTelInput />
      ) : null}
      {isV5Route ? <ReactPhoneInputCustom /> : null}
      {children}
    </>
  );
};

export default AppParent

const languages = [
  {
    lang: 'id',
    name: 'Indonesian',
    direction: 'ltr',
    country: 'ID',
  },
  {
    lang: 'ms',
    name: 'Malay',
    direction: 'ltr',
    country: 'MY',
  },
  {
    lang: 'tr',
    name: 'Türkçe',
    direction: 'ltr',
    country: 'TR',
  },
  {
    lang: 'no',
    name: 'Norwegian',
    direction: 'ltr',
    country: 'NO',
  },
  {
    lang: 'kr',
    name: 'کوردی',
    direction: 'rtl',
    country: 'IQ',
  }
];

export default languages;

import { CLOUDFRONT_LANDING_PAGE_ROOT_URL } from "./app"

const landing_page_arv2 = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/videos/LandingPageARV2.webm`
const landing_page_env2 = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/videos/LandingPageENV2.webm`

const loader = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/videos/loader.webm`

export const videos = {
    landing_page_arv2,
    landing_page_env2,
    loader,
}
import axios from 'axios';
import { setAlert } from './alert';
import Helpers from '../utils/Helpers';
import TagManager from 'react-gtm-module';

import Config from '../utils/Config';
import { appendTextScript, getParameterByName, prepareSMSURI, prepareSMSURIForInAppRedirect } from '../utils/general';
import { COUNTRY_SHORT_NAMES } from '../constants/country';
import { LAYOUT_TYPES, PAYMENT_GATEWAYS, PAYMENT_METHODS } from '../constants/app';
import { startFlowLogger } from '../utils/startFlowLogger';
import { LogService } from '../services/LogService';
import { FacebookService } from '../services/FacebookService';
import { IOperator } from '../Interfaces/CommonInterfaces';
import { decryptAffiliate } from './utils/decryptAffiliate';
import {
  DC_LOCAL_API_SOURCE_NAME,
  ORDER_ID_DC_SS_NAME,
  PRICE_DC_SS_NAME,
  shouldRunDCEvent,
} from '../utils/dc-events';

const API_URL = Config.API_URL;
console.log('API_URL', API_URL);
const WEB_PAGE_URL = Config.WEB_PAGE_URL;

// TYPES
const CHANGE_OPERATOR = 'dcb/changeOperator';
const CHANGE_SOURCE = 'dcb/changeSource';
const SET_PROPS_TO_STATE = 'dcb/setPropsToState';

export const DETECT_OPERATOR = 'dcb/detectOperator';
const SET_PAYMENT_METHOD = 'dcb/setPaymentMethod';

const DCB_HOME_PARTIALLY_UPDATED = 'dcb/dcbHomePartiallyUpdated';
const DCB_STATE_PARTIALLY_UPDATED = 'dcb/dcbStatePartiallyUpdated';
const DCB_HOME_REQUESTED = 'dcb/dcbHomeRequested';
const DCB_HOME_RECEIVED = 'dcb/dcbHomeReceived';
const DCB_SET_HOME_URL = 'dcb/setHomeUrl';
export const DCB_SUBSCRIBE_REQUESTED = 'dcb/dcbSubscribeRequested';
export const DCB_SUBSCRIBE_RECEIVED_STOP_LOADING = 'dcb/dcbSubscribeReceivedStopLoading';
const DCB_SUBSCRIBE_RECEIVED = 'dcb/dcbSubscribeReceived';
const DCB_RESPONSE_RECEIVED_WITH_ERROR = 'dcb/dcbResultReceivedWithError';
const DCB_OTP_REQUEST_SENT = 'dcb/dcbOtpRequestSent';
const DCB_OTP_RESULT_RECEIVED = 'dcb/dcbOtpResultReceived';
const DCB_OTP_RESULT_RECEIVED_SUCCESS = 'dcb/dcbOtpResultReceivedSuccess';

const DCB_OTP_RESULT_RECEIVED_WITH_ERROR = 'dcb/dcbOtpResultReceivedWithError';

const DCB_RESEND_OTP_REQUEST_SENT = 'dcb/dcbResendOtpRequestSent';
const DCB_RESEND_OTP_RESULT_RECEIVED = 'dcb/dcbResendOtpResultReceived';
const DCB_RESEND_OTP_RESULT_RECEIVED_WITH_ERROR = 'dcb/dcbResendOtpResultReceivedWithError';

const DCB_THANKYOU_REQUEST_SENT = 'dcb/dcbThankyouRequestSent';
const DCB_THANKYOU_RESULT_RECEIVED = 'dcb/dcbThankyouResultReceived';
const DCB_THANKYOU_RESULT_RECEIVED_WITH_ERROR = 'dcb/dcbThankyouResultReceivedWithError';

const DCB_REST_TIMER_ACTIVE = 'dcb/dcbResetTimerActive';

const SHOW_PROMO_MODAL = 'dcb/showPromoModal';
const HIDE_PROMO_MODAL = 'dcb/hidePromoModal';
const SET_PROMO_STEP = 'dcb/setPromoStep';
const INVALID_PROMOCODE = 'dcb/invalidPromocode';
const SET_PROMO_CODE = 'dcb/setPromoCode';

const USER_SELECTED_LANGUAGE_MANUALLY = 'USER_SELECTED_LANGUAGE_MANUALLY';
const SET_CACHE_DATA = 'SET_CACHE_DATA';
const CLEAR_CACHE_DATA = 'CLEAR_CACHE_DATA';
const SET_INAPP_REDIRECT = 'dcb/setInappRedirect';

export type DCBState = {
  homeData: {
    country: string;
    operators: IOperator[];
    msisdnLengthMin: number;
    msisdnLengthMax: number;
    fullTCText: string;
    userId?: string;
    source?: string;
    language?: string;
  };
  subscribeData: {
    sessionId: number | string;
    msisdn: string;
    isRedirect: boolean;
    removeTopDisclaimer?: boolean;
  };
  otpData: any; // change after
  resendOtpData: any; // change after
  timerActive: boolean;
  timerCount: number;
  thankyouData: any; // change after
  countryCode: string;
  operator: {
    name: string;
    value: string;
    logo: string;
    isShownMsisdn: number;
    error: string | null;
  }; // the selected operator
  operators: any[];
  operatorSelectedDetails: any; // change after
  phoneNumber: string;
  viewloading: boolean;
  btnLoading: boolean;
  error: any; // change after
  isCountryAvailable: boolean;
  needToResetForm: boolean;
  dataRetrieved: boolean;
  dataRetrievedLanguage: string;
  source: string;
  otpError: boolean;
  fallbackOperator: any; // change after
  reachedThankYou: boolean;
  isPromoModalShown: boolean;
  isPromoValid: boolean;
  promoStep: number;
  invalidPromocodeError: boolean;
  offerCode: string;
  userSelectedLanguageManually: boolean;
  showPhoneOnDesktop: boolean;
  cacheData: any; // change after
  defaultPaymentMethod: string;
  operatorDetectionFinished: boolean;
  homeUrl: string;
  isV3Route: boolean;
  dcbHomeReceived: boolean;
  variationKey?: string;
  isInapp?: boolean;
  isAutoSMSRedirect?: boolean;
  uniquePopUpSubmitState: any;
};

const initialState: DCBState = {
  homeData: {
    country: COUNTRY_SHORT_NAMES.AE,
    operators: [],
    msisdnLengthMin: 5,
    msisdnLengthMax: 12,
    fullTCText: '',
    source: '',
  },
  subscribeData: {
    sessionId: 0,
    msisdn: '',
    isRedirect: false,
  },
  otpData: {},
  resendOtpData: {},
  timerActive: true,
  timerCount: 0,
  thankyouData: {},
  countryCode: '+971', // the selected country code
  operator: {
    name: 'Select your operator',
    value: '',
    logo: '',
    isShownMsisdn: 1,
    error: null,
  }, // the selected operator
  operators: [],
  operatorSelectedDetails: {}, // the details of the selected operator
  phoneNumber: '', // the entered phone number
  viewloading: true,
  btnLoading: false,
  error: {},
  isCountryAvailable: true,
  needToResetForm: false,
  dataRetrieved: false,
  dataRetrievedLanguage: 'en',
  source: 'api-web',
  otpError: false,
  fallbackOperator: null,
  reachedThankYou: false,
  isPromoModalShown: false,
  isPromoValid: false,
  promoStep: 1,
  invalidPromocodeError: false,
  offerCode: '',
  userSelectedLanguageManually: false,
  showPhoneOnDesktop: true,
  cacheData: {},
  defaultPaymentMethod: PAYMENT_METHODS.Phone,
  operatorDetectionFinished: false,
  homeUrl: '',
  isV3Route: false,
  dcbHomeReceived: false,
  variationKey: '',
  isInapp: false,
  isAutoSMSRedirect: false,
  uniquePopUpSubmitState: null,
};

// REDUCER

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_OPERATOR:
      return {
        ...state,
        operator: {
          name: payload.operatorName,
          value: payload.operatorValue,
          logo: payload.operatorLogo,
          isShownMsisdn: payload.isShownMsisdn,
        },
        cacheData: {
          operator: {
            name: payload.operatorName,
            value: payload.operatorValue,
            logo: payload.operatorLogo,
            isShownMsisdn: payload.isShownMsisdn,
          },
        },
        operatorSelectedDetails: payload.fullOperatorDetails,
      };
    case DCB_HOME_REQUESTED:
      return {
        ...state,
        viewloading: true,
      };
    case DCB_HOME_RECEIVED:
      return {
        ...state,
        viewloading: false,
        homeData: payload,
        dataRetrieved: true,
        dataRetrievedLanguage: payload.dataRetrievedLanguage,
        countryCode: `+${payload.countryCode}`,
        // operator: { name: payload.operators[0].name, value: payload.operators[0].value },
        // operatorSelectedDetails: {},
        operators: payload.operators,
        dcbHomeReceived: true,
      };
    case DCB_SET_HOME_URL:
      return {
        ...state,
        homeUrl: payload.homeUrl,
      };
    case DCB_STATE_PARTIALLY_UPDATED:
      return {
        ...state,
        ...payload,
      };
    case DCB_HOME_PARTIALLY_UPDATED:
      return {
        ...state,
        homeData: {
          ...state.homeData,
          ...payload,
        },
      };
    case DCB_SUBSCRIBE_REQUESTED:
      return {
        ...state,
        btnLoading: true,
      };
    case DCB_SUBSCRIBE_RECEIVED:
      return {
        ...state,
        btnLoading: false,
        subscribeData: payload,
        fallbackOperator: payload && payload.operatorName ? payload.operatorName : null,
      };
    case DCB_SUBSCRIBE_RECEIVED_STOP_LOADING:
      return {
        ...state,
        btnLoading: false,
      };
    case DCB_RESPONSE_RECEIVED_WITH_ERROR:
      return {
        ...state,
        btnLoading: false,
        error: payload,
        viewloading: false,
      };

    case DCB_OTP_REQUEST_SENT:
      return {
        ...state,
        btnLoading: true,
      };
    case DCB_OTP_RESULT_RECEIVED:
      return {
        ...state,
        btnLoading: false,
        otpData: payload,
        otpError: !!(payload && payload.otpError),
      };
    case DCB_OTP_RESULT_RECEIVED_SUCCESS:
      return {
        ...state,
        reachedThankYou: true,
      };
    case DCB_OTP_RESULT_RECEIVED_WITH_ERROR:
      return {
        ...state,
        btnLoading: false,
        error: payload,
        otpError: true,
      };

    case DCB_RESEND_OTP_REQUEST_SENT:
      return {
        ...state,
        // btnLoading: true,
        timerActive: false,
        timerCount: state.timerCount + 1,
      };
    case DCB_RESEND_OTP_RESULT_RECEIVED:
      return {
        ...state,
        // btnLoading: false,
        resendOtpData: payload,
      };
    case DCB_RESEND_OTP_RESULT_RECEIVED_WITH_ERROR:
      return {
        ...state,
        // btnLoading: false,
        error: payload,
      };

    case DCB_THANKYOU_REQUEST_SENT:
      return {
        ...state,
        viewLoading: true,
      };
    case DCB_THANKYOU_RESULT_RECEIVED:
      return {
        ...state,
        viewLoading: false,
        thankyouData: payload,
      };
    case DCB_THANKYOU_RESULT_RECEIVED_WITH_ERROR:
      return {
        ...state,
        viewLoading: false,
        error: payload,
      };

    case DCB_REST_TIMER_ACTIVE:
      return {
        ...state,
        timerActive: true,
      };

    case CHANGE_SOURCE:
      return {
        ...state,
        source: payload,
      };

    case SET_PROPS_TO_STATE:
      return {
        ...state,
        propsInState: payload,
      };

    case SHOW_PROMO_MODAL:
      return {
        ...state,
        isPromoModalShown: true,
      };
    case HIDE_PROMO_MODAL:
      return {
        ...state,
        isPromoModalShown: false,
      };

    case SET_PROMO_STEP:
      return {
        ...state,
        promoStep: payload,
        isPromoValid: payload === 3,
        invalidPromocodeError: !(payload === 2 || payload === 3),
      };

    case INVALID_PROMOCODE:
      return {
        ...state,
        invalidPromocodeError: true,
      };

    case SET_PROMO_CODE:
      return {
        ...state,
        offerCode: payload,
      };
    case USER_SELECTED_LANGUAGE_MANUALLY:
      return {
        ...state,
        userSelectedLanguageManually: true,
      };

    case SET_CACHE_DATA:
      return {
        ...state,
        cacheData: payload,
      };

    case CLEAR_CACHE_DATA:
      return {
        ...state,
        cacheData: {},
      };

    case DETECT_OPERATOR:
      return {
        ...state,
        operator: {
          name: payload.operatorName,
          value: payload.operatorValue,
          isShownMsisdn: payload.isShownMsisdn,
          error: null,
        },
        operatorSelectedDetails: payload.fullOperatorDetails,
        operatorDetectionFinished: true,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        defaultPaymentMethod: payload.method,
      };
    case SET_INAPP_REDIRECT:
      return {
        ...state,
        isInapp: payload,
      };
    default:
      return state;
  }
}

// ACTIONS
export const changeOperator = (operatorItem) => async (dispatch) => {
  dispatch({
    type: CHANGE_OPERATOR,
    payload: {
      operatorName: operatorItem.name,
      operatorValue: operatorItem.value,
      operatorLogo: operatorItem.logo,
      isShownMsisdn: operatorItem.isShownMsisdn,
      fullOperatorDetails: operatorItem,
    },
  });

  if (operatorItem.headerEnrichmentUrl && !operatorItem.disableRedirection) {
    window.location.href = operatorItem.headerEnrichmentUrl;
    console.log(`Redirect to ${operatorItem.headerEnrichmentUrl}`);
    return;
  }
};

export const changeSource = (source) => async (dispatch) => {
  dispatch({ type: CHANGE_SOURCE, payload: source });
};

export const setPropsToState = (payload) => async (dispatch) => {
  dispatch({ type: SET_PROPS_TO_STATE, payload });
};

export const getHomeDcb =
  (
    inputFields,
    urlParams,
    apiSource,
    manualPaymentMethod,
    paymentGateway = PAYMENT_GATEWAYS.STRIPE,
    query: URLSearchParams | null = null
  ) =>
  async (dispatch) => {
    dispatch({ type: DCB_HOME_REQUESTED, payload: null });
    const config = Helpers.getAxiosHeadersConfig();

    // @ts-ignore
    window.PapInitializeTracking();

    let operatorPreSelected = 'NONE';

    let homeUrl = `${API_URL}/home`;
    const country = getCountry(inputFields);

    if (inputFields.channel && inputFields.method) {
      if (inputFields.lang)
        homeUrl = `${homeUrl}/${inputFields.lang}/${inputFields.country}/${inputFields.channel}/${inputFields.method}/${inputFields.fbPixelId}/${inputFields.clickId}`;
    } else if (inputFields.mcc && inputFields.mnc && inputFields.subscriberId) {
      homeUrl = `${homeUrl}/inapp`;
      if (inputFields.lang) homeUrl = `${homeUrl}/${inputFields.lang}`;
      if (country) homeUrl = `${homeUrl}/${country}`;
      if (inputFields.mcc) homeUrl = `${homeUrl}/${inputFields.mcc}`;
      if (inputFields.mnc) homeUrl = `${homeUrl}/${inputFields.mnc}`;
      if (inputFields.subscriberId) homeUrl = `${homeUrl}/${inputFields.subscriberId}`;
    } else {
      if (inputFields.isQuery) homeUrl = `${homeUrl}/query`;
      const lang = inputFields.lang || 'en';
      homeUrl = `${homeUrl}/${lang}`;

      if (country) homeUrl = `${homeUrl}/${country}`;
      if (inputFields.billingPlan) homeUrl = `${homeUrl}/billingPlan/${inputFields.billingPlan}`;

      if (inputFields.operator) homeUrl = `${homeUrl}/${inputFields.operator}`;
      if (inputFields.msisdn) homeUrl = `${homeUrl}/${inputFields.msisdn}`;

      if (inputFields.operator) operatorPreSelected = inputFields.operator;
      if (inputFields.billingOption) homeUrl = `${homeUrl}/package/${inputFields.billingOption}`;
    }

    if (inputFields.source) {
      homeUrl = `${homeUrl}?source=${inputFields.source}`;

      if (inputFields.utm_source) homeUrl = `${homeUrl}&utm_source=${inputFields.utm_source}`;
      if (inputFields.utm_medium) homeUrl = `${homeUrl}&utm_medium=${inputFields.utm_medium}`;
      if (inputFields.utm_campaign) homeUrl = `${homeUrl}&utm_campaign=${inputFields.utm_campaign}`;
      if (inputFields.clickId) homeUrl = `${homeUrl}&clickId=${inputFields.clickId}`;
      if (inputFields.fbPixelId) homeUrl = `${homeUrl}&fbPixelId=${inputFields.fbPixelId}`;
      if (inputFields.channel) homeUrl = `${homeUrl}&channel=${inputFields.channel}`;
    } else {
      if (
        inputFields.utm_source ||
        inputFields.utm_medium ||
        inputFields.utm_campaign ||
        inputFields.clickId ||
        inputFields.fbPixelId ||
        inputFields.channel
      ) {
        homeUrl = `${homeUrl}?`;
        if (inputFields.utm_source) homeUrl = `${homeUrl}utm_source=${inputFields.utm_source}&`;
        if (inputFields.utm_medium) homeUrl = `${homeUrl}utm_medium=${inputFields.utm_medium}&`;
        if (inputFields.utm_campaign)
          homeUrl = `${homeUrl}utm_campaign=${inputFields.utm_campaign}&`;
        if (inputFields.clickId) homeUrl = `${homeUrl}clickId=${inputFields.clickId}&`;
        if (inputFields.fbPixelId) homeUrl = `${homeUrl}fbPixelId=${inputFields.fbPixelId}&`;
        if (inputFields.channel) homeUrl = `${homeUrl}channel=${inputFields.channel}&`;

        homeUrl = homeUrl.substr(0, homeUrl.length - 1);
      }
    }

    if (inputFields.allQueryParams && inputFields.allQueryParams.length > 0) {
      // loop over each query param and append it to homeUrl
      if (!homeUrl.includes('?')) homeUrl = `${homeUrl}?`;

      // handle case if no other parameters exist, remove first &
      for (let qParam of inputFields.allQueryParams) {
        homeUrl = `${homeUrl}&${qParam.qKey}=${qParam.qValue}`;
      }

      // substr to remove trailing & not needed
    }

    if (query) {
      const isDVRedirection = query.get('action') === 'dvredirection';
      const isHERedirection = query.get('action') === 'heredirection';

      if (isDVRedirection) {
        const billingOption = query.get('billingOption') || 'weekly';

        homeUrl = homeUrl.includes('?')
          ? `${homeUrl}&isQueryCallback=1&billingOption=${billingOption}`
          : `${homeUrl}?isQueryCallback=1&billingOption=${billingOption}`;
      }

      if (isHERedirection) {
        homeUrl = homeUrl.includes('?')
          ? `${homeUrl}&isQueryCallback=1`
          : `${homeUrl}?isQueryCallback=1`;
      }
    }

    try {
      const res = await axios.get(homeUrl, config);
      let { data } = res.data;
      if (data.script) {
        appendTextScript(data.script);
      }

      if (data.redirectURL && !inputFields.disableRedirection) {
        window.location.href = data.redirectURL;
        console.log(`Redirect to ${data.redirectURL}`);
        return;
      }

      const receivedOperators = data.operators;

      if (operatorPreSelected !== 'NONE') {
        let chosenOperator = receivedOperators.filter((op) => op.value === operatorPreSelected);
        let otherOperators = receivedOperators.filter((op) => op.value !== operatorPreSelected);

        data.operators = [...chosenOperator, ...otherOperators];
      } else if (inputFields.mcc && inputFields.mnc) {
        const resOp = await axios.get(
          `${API_URL}/home/operator/${inputFields.mcc}/${inputFields.mnc}`
        );
        const matchedOperator = resOp.data.data;

        let chosenOperator = receivedOperators.filter((op) => op.value === matchedOperator.value);
        let otherOperators = receivedOperators.filter((op) => op.value !== matchedOperator.value);

        data.operators = [...chosenOperator, ...otherOperators];
      }

      if (data.termsText && data.termsText !== '') {
        let termsText = data.termsText;
        data.fullTCText = termsText;

        if (inputFields.lang) {
          data.dataRetrievedLanguage = inputFields.lang;
        } else {
          data.dataRetrievedLanguage = 'en';
        }
        const pageLink = window.location.href;
        if (pageLink.includes('promo') && !pageLink.includes('new') && data.operators) {
          data.operators = data.operators.filter(
            (operatorItem) => operatorItem.value !== 'KSA-STC'
          );
        }
      }

      const { utm_source } = inputFields;

      if (utm_source) {
        const decrypted_utm_source = await decryptAffiliate(utm_source);
        if (decrypted_utm_source) {
          inputFields.utm_source = decrypted_utm_source;
        }
      }

      startFlowLogger(inputFields, apiSource, manualPaymentMethod, data.country, paymentGateway);
      dispatch({ type: DCB_SET_HOME_URL, payload: { homeUrl } });
      dispatch({ type: DCB_HOME_RECEIVED, payload: data });
    } catch (err) {
      console.log('getHomeDcbResponseError', err);
      dispatch({ type: DCB_RESPONSE_RECEIVED_WITH_ERROR, payload: null });

      if (inputFields.lang) {
        if (inputFields.lang === 'en') {
          dispatch(
            setAlert('Error processing request. Your country might not be supported', 'danger')
          );
        } else if (inputFields.lang === 'ar') {
          dispatch(setAlert('طلب معالجة خطأ. قد لا يتم دعم بلدك', 'danger'));
        } else if (inputFields.lang === 'fr') {
          dispatch(
            setAlert(
              "Erreur lors du traitement de la demande. Votre pays n'est peut-être pas pris en charge",
              'danger'
            )
          );
        } else if (inputFields.lang === 'es') {
          dispatch(
            setAlert(
              'Error al procesar la solicitud. Es posible que su país no sea compatible',
              'danger'
            )
          );
        } else if (inputFields.lang === 'id') {
          dispatch(
            setAlert(
              'Permintaan tidak bisa diproses. Aplikasi ini tidak tersedia di negara Anda',
              'danger'
            )
          );
        } else if (inputFields.lang === 'ms') {
          dispatch(
            setAlert(
              'Ralat semasa memproses permintaan. Negara anda mungkin tidak disokong',
              'danger'
            )
          );
        } else if (inputFields.lang === 'tr') {
          dispatch(setAlert('İstek işlenirken hata oluştu. Ülkeniz desteklenmeyebilir', 'danger'));
        } else if (inputFields.lang === 'no') {
          dispatch(
            setAlert(
              'Feil under behandling av forespørsel. Landet ditt støttes kanskje ikke',
              'danger'
            )
          );
        }
      } else {
        dispatch(
          setAlert('Error processing request. Your country might not be supported', 'danger')
        );
      }
    }
  };

const getCountry = (inputFields) => {
  if (inputFields.selectedCountry || inputFields.country) {
    return inputFields.selectedCountry ? inputFields.selectedCountry : inputFields.country;
  }
  return undefined;
};

interface IConfiguration {
  isDCBOld?: boolean;
  redirectRoute?: string | null;
  isHlrlookup?: boolean;
  prevErrors?: any;
  phoneNumber?: string;
  callback?: () => void;
  onCatchCallback?: (
    title?: { value?: string; translateKey?: string },
    body?: { value?: string; translateKey?: string; code?: string }
  ) => void;
}

export const subscribeUsingPhone =
  (formData, history, configuration: IConfiguration = {}, isInappRedirect = false) =>
  async (dispatch) => {
    const {
      redirectRoute = null,
      isHlrlookup = false,
      prevErrors,
      phoneNumber,
      callback = () => {},
      onCatchCallback = () => {},
    } = configuration;
    dispatch({ type: DCB_SUBSCRIBE_REQUESTED, payload: null });
    const config = Helpers.getAxiosHeadersConfig();

    let phone;
    let countryCode;

    if (formData.mobile) {
      phone = formData.mobile;
    } else if (formData.phone) {
      phone = formData.phone;
    }

    if (formData.mobileCountryCode) {
      countryCode = formData.mobileCountryCode;
    } else if (formData.countryCode) {
      countryCode = formData.countryCode;
    }

    const subscribeRequestBody = {
      subscriptionMethodType: 'mobile',
      mobile: formData.isShownMsisdn ? phone : 'no number',
      mobileCountryCode: countryCode?.substring(1), // remove the +
      userOperator: formData.operator,
      subscriberId: formData.subscriberId ? formData.subscriberId : '',
      lang: formData.lang || formData.language || 'en',
      source: formData.source ? formData.source : 'api-web',
      channel: formData.channel ? formData.channel : 'organic',
      method: formData.method ? formData.method : 'web',
      fbPixelId: formData.fbPixelId ? formData.fbPixelId : '981697515182465',
      clickId: formData.clickId ? formData.clickId : '',
      utm_source: formData.utm_source ? formData.utm_source : '',
      utm_medium: formData.utm_medium ? formData.utm_medium : '',
      utm_campaign: formData.utm_campaign ? formData.utm_campaign : '',
      billingOption: formData.billingOption ? formData.billingOption : 'weekly',
      SubscriptionOptionMobile: '',
      redirectOTP: formData.isRedirectOTP ? formData.isRedirectOTP : false,
      redirectUrlBase: WEB_PAGE_URL,
      urlReferrer: window.location.href,
      offerCode: formData.offerCode ? formData.offerCode : '',
      shieldUniqid: formData.shieldUniqid ? formData.shieldUniqid : '',
      userId: formData.userId,
      correlationId: formData?.correlationId ? formData.correlationId : '',
      antiFraudUniqueId: formData?.antiFraudUniqueId ? formData.antiFraudUniqueId : '',
      operationId: formData?.operationId ? formData.operationId : '',
      identificationId: formData?.identificationId ? formData.identificationId : '',
    };

    if (formData.aggregatorClickId) {
      // @ts-ignore
      subscribeRequestBody.aggregatorClickId = formData.aggregatorClickId;
    }

    LogService.dcbClickButtonSubscribe({
      operator: formData.operator,
      msisdn: formData.phone,
    });

    try {
      const res = await axios.post(`${API_URL}/home/subscribe`, subscribeRequestBody, config);

      let { data, statusCode, message, sessionId } = res.data;

      const isRedirectSMS = data?.isSMSRedirect;

      if (isRedirectSMS) {
        const phoneNumber = data.smsShortCode;
        const message = encodeURIComponent(data.smsKeyword);

        if (isInappRedirect) {
          const currentLink = window.location;
          const redirectUrl = `${currentLink}?${prepareSMSURIForInAppRedirect(
            phoneNumber,
            message
          )}` as Location | (string & Location);
          window.location = redirectUrl;
          return;
        } else {
          const smsUri = prepareSMSURI(phoneNumber, message);
          dispatch({ type: DCB_SUBSCRIBE_RECEIVED, payload: {} });
          window.location = smsUri as Location | (string & Location);
          localStorage.removeItem('detectedOperators');
          return;
        }
      }

      // if(data.apiSubscribeServiceID) {
      //   appendTextScript(data.apiSubscribeServiceID, 0)
      // }

      if (formData.selectedPrice && data) {
        data.selectedPrice = formData.selectedPrice;
      }

      const apiSourceFromSessionStorage = sessionStorage.getItem(DC_LOCAL_API_SOURCE_NAME) || '';
      const isInitiativeFlow = shouldRunDCEvent(apiSourceFromSessionStorage);

      const orderIdValue = data?.sessionId || 0;
      const priceValue = data?.price || 0;

      if (isInitiativeFlow) {
        sessionStorage.setItem(PRICE_DC_SS_NAME, priceValue);
        sessionStorage.setItem(ORDER_ID_DC_SS_NAME, orderIdValue);
      }

      dispatch({ type: DCB_SUBSCRIBE_RECEIVED, payload: data });

      // check type, and then know whether to forward to enter OTP or external site

      if (statusCode === 401 && isHlrlookup) {
        onCatchCallback(
          {
            translateKey: 'something_went_wrong',
          },
          message
            ? { value: message, code: data?.apiUnifiedResponseCode || 0 }
            : { translateKey: 'something_went_wrong_proceed_with_alternative_payment' }
        );
        dispatch({ type: SET_PAYMENT_METHOD, payload: { method: PAYMENT_METHODS.Card } });
        return;
      } else if (statusCode !== 200) {
        if (phoneNumber) {
          if (prevErrors) {
            sessionStorage.setItem(
              'detectOperatorError',
              JSON.stringify({
                ...prevErrors,
                [phoneNumber]: message,
              })
            );
          } else {
            sessionStorage.setItem(
              'detectOperatorError',
              JSON.stringify({ [phoneNumber]: message })
            );
          }
        }
        onCatchCallback(
          {
            translateKey: 'something_went_wrong',
          },
          message
            ? { value: message, code: data?.apiUnifiedResponseCode || 0 }
            : { translateKey: 'something_went_wrong_proceed_with_alternative_payment' }
        );
        dispatch(setAlert(message, 'danger'));
        return;
      }

      let { msisdn, isRedirect, redirectURL, subscriberId, operator } = data;

      if (isRedirect && isRedirect === true && redirectURL) {
        LogService.dcbHostedFlow(
          { plusDCBSessionId: sessionId },
          {
            redirectURL,
            operator,
            msisdn,
          }
        );
        // route to redirectURL
        window.location.href = redirectURL;
        // console.log(`Redirect to ${redirectURL}`);
      } else if (redirectRoute) {
        history.push(redirectRoute);
      } else {
        LogService.dcbOtpFlow({
          subscriberId,
          plusDCBSessionId: sessionId,
          operator: formData.operator,
          msisdn,
        });

        const noRedirect = getParameterByName('noRedirect');
        const isTVLayout = getParameterByName('layout_type') === LAYOUT_TYPES.TV;
        const query = noRedirect
          ? isTVLayout
            ? `?noRedirect=true&layout_type=tv`
            : '?noRedirect=true'
          : isTVLayout
          ? '?layout_type=tv'
          : '';
        history.push(`/confirm-pin${query}`);
      }
    } catch (err) {
      onCatchCallback(
        { translateKey: 'something_went_wrong' },
        { translateKey: 'something_went_wrong_proceed_with_alternative_payment' }
      );
      dispatch(setAlert('Error processing request. Please contact support', 'danger'));
      dispatch({ type: DCB_RESPONSE_RECEIVED_WITH_ERROR, payload: null });
    } finally {
      callback();
    }
  };

export const submitOTP =
  (formData, history, redirectRoute = null) =>
  async (dispatch) => {
    dispatch({ type: DCB_OTP_REQUEST_SENT, payload: null });
    const config = Helpers.getAxiosHeadersConfig();

    const otpRequestBody = {
      sessionId: formData.sessionId,
      lang: formData.lang,
      otp: formData.otp,
      antifraudUniqueId: formData?.antifraudUniqueId || '',
      clickId: formData?.clickId || '',
    };

    LogService.dcbEnterOtpEvent(formData.otp);
    LogService.dcbClickButtonVerify(formData.otp);

    try {
      const res = await axios.post(`${API_URL}/home/subscribe/otp`, otpRequestBody, config);
      console.log('submitOTPResponseData', res.data);

      let { data, statusCode, message } = res.data;

      if (data) {
        if (statusCode === 200) {
          data.otpError = false;
        } else {
          data.otpError = true;
        }
      }

      dispatch({ type: DCB_OTP_RESULT_RECEIVED, payload: data });

      if (statusCode === 200 && data.activationURL) {
        LogService.dcbFinishPinFlow();

        let tagManagerArgs = {
          dataLayer: {
            event: 'subscribe',
            eventCategory: 'DCB',
            eventAction: 'subscribe',
            eventValue: data.price || 'USD',
            eventLabel: 'Plus Subscription',
          },
          dataLayerName: 'SubscriptionDataLayer',
        };

        TagManager.dataLayer(tagManagerArgs);

        FacebookService.trackSubscribe(data.price);

        let a_aid = Helpers.getUrlParameter('a_aid') || '';
        console.log('a_aid from query: ', a_aid);

        if (data.papName) {
          const decryptedPapName = await decryptAffiliate(data.papName);
          if (decryptedPapName) {
            data.papName = decryptedPapName;
          }
        }

        // @ts-ignore
        window.PapRegisterLead(
          formData.sessionId,
          data.papData1,
          data.papData2,
          data.papData3,
          data.papName
        );
        // window.PapTrackSale(formData.sessionId, data.price);

        dispatch({ type: SET_PROMO_STEP, payload: 1 });
        try {
          if (localStorage) {
            localStorage.setItem('isPromoValid', 'false');
            localStorage.setItem('appliedPromocode', '');
          }
        } catch (err) {}

        // need to check if source is mobile, then need to append source, message, and price as query params
        let appendQueryParams = '';
        const noRedirect = getParameterByName('noRedirect');
        const isTVLayout = getParameterByName('layout_type') === LAYOUT_TYPES.TV;
        const query = noRedirect
          ? isTVLayout
            ? `?noRedirect=true&layout_type=tv`
            : '?noRedirect=true'
          : isTVLayout
          ? '?layout_type=tv'
          : '';
        if (formData.source === 'api-app') {
          appendQueryParams = `status=success&message=${message}&price=${data.price}`;

          console.log('appendQueryParams', appendQueryParams);

          // track adjust event for dcb in app
          try {
            await axios.get(
              `https://s2s.adjust.com/event?s2s=1&event_token=6dqpmp&app_token=28h6opytxvb4&&gps_adid=ebd76ecb-9fe5-4c7e-87d8-2b416a2dd0b6`
            );
          } catch (errr) {}

          window.location.href = `${WEB_PAGE_URL}?${appendQueryParams}`;

          // history.push({
          //   pathname: `/dcb`,
          //   state: { activationURL: data.activationURL, isWhite: false },
          //   search: appendQueryParams
          // });
        } else if (redirectRoute) {
          history.push({ pathname: redirectRoute, search: query });
        } else {
          history.push({
            pathname: `/thankyou`,
            state: { activationURL: data.activationURL, isWhite: false },
            search: query,
          });
        }

        // history.push({
        //   pathname: `/dcb/thankyou`,
        //   state: { activationURL: data.activationURL, isWhite: false },
        //   search: appendQueryParams
        // });
      } else {
        dispatch(setAlert(message, 'danger'));

        if (formData.source === 'api-app') {
          window.location.href = `${WEB_PAGE_URL}?status=failure&message=${message}&price=`;
        }
      }
    } catch (err) {
      console.log('submitOTPResponseError', err);

      if (otpRequestBody && otpRequestBody.lang) {
        if (otpRequestBody.lang === 'en') {
          dispatch(
            setAlert(
              'Something is wrong with your PIN code, please make sure it is written correct and try again',
              'danger'
            )
          );
        } else if (otpRequestBody.lang === 'ar') {
          dispatch(
            setAlert(
              'هناك خطأ ما في الكود الخاص بك ، يرجى التأكد من كتابته بشكل صحيح والمحاولة مرة أخرى',
              'danger'
            )
          );
        } else if (otpRequestBody.lang === 'fr') {
          dispatch(
            setAlert(
              "Il y a un problème avec votre code PIN, veuillez vous assurer qu'il est écrit correctement et réessayer",
              'danger'
            )
          );
        } else if (otpRequestBody.lang === 'es') {
          dispatch(
            setAlert(
              'Algo está mal con su código PIN, asegúrese de que esté escrito correctamente y vuelva a intentarlo',
              'danger'
            )
          );
        } else if (otpRequestBody.lang === 'id') {
          dispatch(
            setAlert(
              'Ada yang salah dengan kode PIN Anda, harap pastikan bahwa penulisannya benar dan coba lagi',
              'danger'
            )
          );
        } else if (otpRequestBody.lang === 'ms') {
          dispatch(
            setAlert(
              'Ada yang tidak kena dengan kod PIN anda, pastikan ia ditulis dengan betul dan cuba lagi',
              'danger'
            )
          );
        } else if (otpRequestBody.lang === 'tr') {
          dispatch(
            setAlert(
              'PIN kodunuzda bir sorun var, lütfen doğru yazıldığından emin olun ve tekrar deneyin',
              'danger'
            )
          );
        } else if (otpRequestBody.lang === 'no') {
          dispatch(
            setAlert(
              'Noe er galt med PIN -koden din. Kontroller at den er riktig skrevet og prøv igjen',
              'danger'
            )
          );
        }
      } else {
        dispatch(
          setAlert(
            'Something is wrong with your PIN code, please make sure it is written correct and try again',
            'danger'
          )
        );
      }

      dispatch({ type: DCB_OTP_RESULT_RECEIVED_WITH_ERROR, payload: null });
      if (formData.source === 'api-app') {
        window.location.href = `${WEB_PAGE_URL}?status=failure&message=Error&price=`;
      }
    }
  };

export const resendOTP =
  (formData, history, isV3Route = false) =>
  async (dispatch) => {
    LogService.dcbClickButtonResend(formData.otp);
    const config = Helpers.getAxiosHeadersConfig();

    const resendOtpRequestBody = {
      sessionId: formData.sessionId,
      lang: formData.lang,
    };

    console.log('resendOtpRequestBody', resendOtpRequestBody);
    dispatch({ type: DCB_RESEND_OTP_REQUEST_SENT, payload: null });

    try {
      const res = await axios.post(
        `${API_URL}/home/subscribe/resendotp`,
        resendOtpRequestBody,
        config
      );

      // const jsstring = JSON.parse(res.data.data.response).jsstring

      // if(jsstring) {
      //   appendTextScript(jsstring, 0)
      // }

      console.log('resendOTPResponseData', res.data);
      let { data, statusCode, message } = res.data;
      if (statusCode !== 200) {
        dispatch(setAlert(message, 'danger'));
        dispatch({ type: DCB_RESEND_OTP_RESULT_RECEIVED_WITH_ERROR, payload: null });
        return;
      } else {
        dispatch({ type: DCB_RESEND_OTP_RESULT_RECEIVED, payload: data });
        if (!isV3Route) {
          dispatch(setAlert('PIN sent!', 'info'));
        }
      }
    } catch (err) {
      console.log('resendtOTPResponseError', err);
      dispatch(setAlert('Error processing request. Please contact support', 'danger'));
      dispatch({ type: DCB_RESEND_OTP_RESULT_RECEIVED_WITH_ERROR, payload: null });
    }
  };

export const getThankYou =
  (sessionId, isShownMsisdn = false) =>
  async (dispatch) => {
    const config = Helpers.getAxiosHeadersConfig();
    dispatch({ type: DCB_THANKYOU_REQUEST_SENT, payload: null });

    try {
      const res = await axios.get(`${API_URL}/home/thankyou/${sessionId}`, config);
      let { data, statusCode, message } = res.data;
      console.log('getThankYouResponseData', data);

      if (statusCode === 200) {
        if (!initialState.otpData.subscriberId && !isShownMsisdn) {
          LogService.dcbFinishHostedFlow();
        }

        let tagManagerArgs = {
          dataLayer: {
            event: 'subscribe',
            eventCategory: 'DCB',
            eventAction: 'subscribe',
            eventValue: data.sessionPrice || 'AED',
            eventLabel: 'Plus Subscription',
          },
          dataLayerName: 'SubscriptionDataLayer',
        };

        TagManager.dataLayer(tagManagerArgs);
        FacebookService.trackSubscribe(data.sessionPrice);

        let a_aid = Helpers.getUrlParameter('a_aid') || '';
        console.log('a_aid from query: ', a_aid);

        if (data.papName) {
          const decryptedPapName = await decryptAffiliate(data.papName);
          if (decryptedPapName) {
            data.papName = decryptedPapName;
          }
        }

        // @ts-ignore
        window.PapRegisterLead(
          data.sessionId,
          data.papData1,
          data.papData2,
          data.papData3,
          data.papName
        );
        // window.PapTrackSale(data.sessionId, data.sessionPrice);

        dispatch({ type: DCB_THANKYOU_RESULT_RECEIVED, payload: data });

        let appendQueryParams = '';
        if (data.source && data.source === 'api-app') {
          appendQueryParams = `status=success&message=${message}&price=${data.price}`;

          // track adjust event for dcb in app
          try {
            await axios.get(
              `https://s2s.adjust.com/event?s2s=1&event_token=6dqpmp&app_token=28h6opytxvb4&&gps_adid=ebd76ecb-9fe5-4c7e-87d8-2b416a2dd0b6`
            );
          } catch (errr) {}

          window.location.href = `${WEB_PAGE_URL}/thankyou?${appendQueryParams}`;
        }
      } else {
        if (!initialState.otpError && !isShownMsisdn) {
          LogService.dcbFailedHostedFlow();
        }
        dispatch(setAlert('Error processing request. Please contact support', 'danger'));
        dispatch({ type: DCB_THANKYOU_RESULT_RECEIVED_WITH_ERROR, payload: null });

        if (data.source && data.source === 'api-app') {
          window.location.href = `${WEB_PAGE_URL}?status=failure&message=${message}&price=`;
        }
      }
    } catch (err) {
      dispatch(setAlert('Error processing request. Please contact support', 'danger'));
      dispatch({ type: DCB_THANKYOU_RESULT_RECEIVED_WITH_ERROR, payload: null });
    }
  };

export const resetTimerActive = () => async (dispatch) => {
  dispatch({ type: DCB_REST_TIMER_ACTIVE, payload: null });
};

export const reachedThankYouPage = () => async (dispatch) => {
  dispatch({ type: DCB_OTP_RESULT_RECEIVED_SUCCESS, payload: null });
};

export const showPromoModal = () => async (dispatch) => {
  dispatch({ type: SHOW_PROMO_MODAL, payload: null });
};

export const hidePromoModal = () => async (dispatch) => {
  dispatch({ type: HIDE_PROMO_MODAL, payload: null });
};

export const applyPromocode = (promocode) => async (dispatch) => {
  console.log('promocode entered ' + promocode);
  dispatch({ type: SET_PROMO_STEP, payload: 2 });
  let body = { offerCode: promocode };
  const config = Helpers.getAxiosHeadersConfig();

  try {
    const res = await axios.post(`${API_URL}/home/offer/validate`, body, config);
    let { data } = res.data;
    console.log('validateOfferData', data);

    if (data && data.points) {
      dispatch({ type: SET_PROMO_STEP, payload: 3 });
      dispatch({ type: SET_PROMO_CODE, payload: promocode });

      try {
        if (localStorage) {
          localStorage.setItem('isPromoValid', 'true');
          localStorage.setItem('appliedPromocode', promocode);
        }
      } catch (err) {}
    } else {
      dispatch({ type: SET_PROMO_STEP, payload: 1 });
      dispatch({ type: INVALID_PROMOCODE, payload: null });
      try {
        if (localStorage) {
          localStorage.setItem('isPromoValid', 'false');
          localStorage.setItem('appliedPromocode', '');
        }
      } catch (err) {}
    }
  } catch (err) {
    console.log('error in validateoffer endpoint');
    dispatch({ type: SET_PROMO_STEP, payload: 1 });
    dispatch({ type: INVALID_PROMOCODE, payload: null });

    try {
      if (localStorage) {
        localStorage.setItem('isPromoValid', 'false');
        localStorage.setItem('appliedPromocode', '');
      }
    } catch (err) {}
  }
};


export const setPromocodeAsAppied = () => (dispatch) => {
  let promocode = '';
  let promocodeLS;
  try {
    promocodeLS = localStorage.getItem('appliedPromocode');
  } catch (err) {}

  dispatch({ type: SET_PROMO_STEP, payload: 3 });
  dispatch({ type: SET_PROMO_CODE, payload: promocodeLS ? promocodeLS : promocode });
};

export const setUserSelectedLanguageManually = () => (dispatch) => {
  dispatch({ type: USER_SELECTED_LANGUAGE_MANUALLY, payload: null });
};

export const setCacheData = (data) => (dispatch) => {
  dispatch({ type: SET_CACHE_DATA, payload: data });
};

export const clearCacheData = () => (dispatch) => {
  dispatch({ type: CLEAR_CACHE_DATA, payload: null });
};

export const DCBWasSubmitted = () => async (dispatch) => {
  dispatch({
    type: DCB_SUBSCRIBE_REQUESTED,
    payload: null,
  });
};

export const DCBSubmittedStopLoading = () => async (dispatch) => {
  dispatch({
    type: DCB_SUBSCRIBE_RECEIVED_STOP_LOADING,
    payload: null,
  });
};

export const updatePartiallyDCBState = (stateFields) => async (dispatch) => {
  dispatch({
    type: DCB_STATE_PARTIALLY_UPDATED,
    payload: stateFields,
  })
}

export const updatePartiallyHomeData = (homeData) => async (dispatch) => {
  dispatch({
    type: DCB_HOME_PARTIALLY_UPDATED,
    payload: homeData,
  })
}

export const setInappRedirect = (isRedirect: boolean) => async (dispath) => {
  dispath({
    type: SET_INAPP_REDIRECT,
    payload: isRedirect,
  });
};

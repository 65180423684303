import { CLOUDFRONT_LANDING_PAGE_ROOT_URL } from "./app";
import { videos } from "./videos";

const dcb_lp_banner =
 `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/DCB-LP-banner.webp`;
const lp_banner_ksa_en2 =
`${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/lp_banner_ksa_en2.webp`;
const lp_banner_ksa_ar2 =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/lp_banner_ksa_ar2.webp`;
const hlrlookup_banner = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/LP2.webp`;
const days_free_vip_en =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/viplp_banner_free+trial_en%402x.webp`;
const days_free_vip_ar =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/viplp_banner_free+trial_ar%402x.webp`;
const raffle_en = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/raffle-en-sm.webp`;
const raffle_ar = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/raffle-ar-sm.webp`;
const iphone_banner =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/iPhone-13-Pro_raffle_480x320.webp`;
const vip_social_media_en =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/lp_banner_ksa_en.webp`;
const vip_social_media_ar =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/lp_banner_ksa_ar.webp`;
const voucher_banner_en =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/voucher-vip.webp`;
const voucher_banner_ar =
  `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/voucher-vip-ar.webp`;

const cutting_edge_tech = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/cuting_edge_technolody_banner_fv3.png`
const multiple_devices = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/optimized-png/multidevices_x4.png`
const success_subs_background = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/success_subs_background.png`

// Videos

const main_video_new_en = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/Landing-page-2023-EN_min.mp4`
const main_video_new_ar = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/Landing-page-2023-AR_min.mp4`
const main_video_new_id = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/Landing-page-2023-ID_min.mp4`
const main_video_new_my = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/Landing-page-2023-MY_min.mp4`
const main_video_new_tr = `${CLOUDFRONT_LANDING_PAGE_ROOT_URL}/banners/Landing-page-2023-TR_min.mp4`

export const defaultBannerConfig = {
  en: videos.landing_page_env2,
  ar: videos.landing_page_arv2,
  id: main_video_new_id,
  my: main_video_new_my,
  tr: main_video_new_tr,
}


export const banners = {
  dcb_lp_banner,
  lp_banner_ksa_en2,
  lp_banner_ksa_ar2,
  hlrlookup_banner,
  days_free_vip_en,
  days_free_vip_ar,
  raffle_en,
  raffle_ar,
  iphone_banner,
  vip_social_media_en,
  vip_social_media_ar,
  voucher_banner_en,
  voucher_banner_ar,
  cutting_edge_tech,
  multiple_devices,
  success_subs_background,
  main_video_new_en,
  main_video_new_ar,
  main_video_new_id,
  main_video_new_my,
  main_video_new_tr
};

import { combineReducers } from 'redux';

import configReducer from './config'
import alertReducer from './alert';
import globalReducer from './global';
import subscriptionReducer from './subscription';
import appUIReducer, { AppUIState } from './appUI';
import dcbReducer, { DCBState } from './dcb';

const rootReducer =  combineReducers({
  configs: configReducer,
  alert: alertReducer,
  global: globalReducer,
  subscription: subscriptionReducer,
  dcb: dcbReducer,
  appUI: appUIReducer,
});

export interface RootState {
  configs: any,
  alert: any,
  global: any,
  subscription: any,
  dcb: DCBState
  appUI: AppUIState
}

export default rootReducer

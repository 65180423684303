import React from 'react';
import styles from './FormMessage.module.css';
import { ErrorIcon } from '../../shared/icons/ErrorIcon';
import { SuccessTickIcon } from '../../shared/icons/SuccessTickIcon';
import cn from 'classnames';

interface Props {
  message: string;
  type: 'success' | 'error';
}

export const FormMessage: React.FC<Props> = ({ message, type }) => {
  const isSuccess = type === 'success';

  return (
    <div className={styles.wrapper}>
      {isSuccess ? <SuccessTickIcon /> : <ErrorIcon />}
      <p
        className={cn(styles.messageText, {
          [styles.successMessageText]: isSuccess,
        })}
      >
        {message}
      </p>
    </div>
  );
};

import React, { useEffect, useMemo, useState } from 'react';
import styles from './B2BForm.module.css';
import { Button } from '../Button/Button';
import { B2BFormField } from '../B2BFormField/B2BFormField';
import { useTranslation } from 'react-i18next';
import { getDirFromLocale, isEmailValid, isNameValid, isPhoneValid } from '../../../utils/general';
import { useAppSelector } from '../../../store/hooks';
import { B2BLanguageDropdown } from '../B2BLanguageDropdown/B2BLanguageDropdown';
import { B2BFormText } from '../B2BFormText/B2BFormText';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import Config from '../../../utils/Config';
import { useResponsive } from '../../../hooks/useResponsive';
import { FormMessage } from '../FormMessage/FormMessage';
import { AppModal } from '../../shared/Modal/Modal';

export const B2BForm: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useAppSelector((state) => state.global);

  const [isModalOpened, setModalOpened] = useState(false);
  const [country, setCountry] = useState('');

  const handleModalClose = () => setModalOpened(false);

  const [isMobile] = useResponsive('MOBILE');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [formSubmittedSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState<string>('');

  useEffect(() => {
    axios.get(`${Config.API_URL}/v1/country/detect`).then(({ data }) => {
      setCountry(data?.data?.isoCode?.toUpperCase());
    });
  }, []);

  const nameFieldPlaceholder = t('enter_your_name');
  const emailFieldPlaceholder = t('enter_your_email');
  const phoneNumberFieldPlaceholder = t('enter_your_number');

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const formIsValid = useMemo(() => {
    return isNameValid(name) && isEmailValid(email) && isPhoneValid(phoneNumber, country);
  }, [name, email, phoneNumber, country]);

  const isSubmitButtonDisabled = !formIsValid || isLoading;

  let generalMessageTimeout: NodeJS.Timeout;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    setFormSubmittedSuccessfully('');
    setError('');

    clearTimeout(generalMessageTimeout);

    const validName = isNameValid(name);
    const validEmail = isEmailValid(email);
    const validPhone = isPhoneValid(phoneNumber, country);

    if (!validName) {
      setNameError(true);
    }
    if (!validEmail) {
      setEmailError(true);
    }
    if (!validPhone) {
      setPhoneNumberError(true);
    }

    if (!validName || !validEmail || !validPhone) return;

    if (!executeRecaptcha) {
      setModalOpened(true);
      setError(`${t('smth_went_wrong_try_again')}`);
      setIsLoading(false);
      return;
    }

    const token = await executeRecaptcha('formSubit');

    const userData = {
      name,
      email,
      msisdn: phoneNumber,
      recaptcha: token,
    };

    const onError = () => {
      setModalOpened(true);
      setError(`${t('smth_went_wrong_try_again')}`);

      generalMessageTimeout = setTimeout(() => {
        setError('');
      }, 5000);
    };

    axios
      .post(`${Config.API_URL}/v1/zendesk/ticket`, userData)
      .then((res) => {
        if (res.status === 200 && res.data.statusCode === 200) {
          setModalOpened(true);
          setFormSubmittedSuccessfully(`${t('b2b_form_success')}`);

          generalMessageTimeout = setTimeout(() => {
            setFormSubmittedSuccessfully('');
          }, 5000);
        } else {
          onError();
        }
      })
      .catch(() => {
        onError();
      })
      .finally(() => {
        setIsLoading(false);
        setName('');
        setEmail('');
        setPhoneNumber('');
      });
  };

  return (
    <div className={styles.wrapper} dir={getDirFromLocale(language.locale)}>
      <div className={styles.language_wrapper} dir={'ltr'}>
        <B2BLanguageDropdown />
      </div>
      <div className={styles.text_wrapper}>
        <B2BFormText />
      </div>

      <AppModal
        show={Boolean(isMobile && error && isModalOpened)}
        onClose={handleModalClose}
        onSecondaryAction={handleModalClose}
        type="error"
        secondaryText={t('ok')}
        title={{
          value: error,
        }}
        withHeaderIcon
      />

      <AppModal
        show={Boolean(isMobile && formSubmittedSubmittedSuccessfully && isModalOpened)}
        onClose={handleModalClose}
        onSecondaryAction={handleModalClose}
        type="success"
        secondaryText={t('ok')}
        title={{
          value: formSubmittedSubmittedSuccessfully,
        }}
        withHeaderIcon
      />

      <form className={styles.form_wrapper} onSubmit={handleSubmit}>
        <div className={styles.formFiledsWrapper}>
          <B2BFormField
            error={nameError}
            onError={setNameError}
            value={name}
            onChange={setName}
            placeholder={nameFieldPlaceholder}
            name="name"
          />
          <B2BFormField
            error={emailError}
            onError={setEmailError}
            value={email}
            onChange={setEmail}
            placeholder={emailFieldPlaceholder}
            name="email"
          />
          <B2BFormField
            error={phoneNumberError}
            onError={setPhoneNumberError}
            value={phoneNumber}
            onChange={setPhoneNumber}
            placeholder={phoneNumberFieldPlaceholder}
            name="phoneNumber"
            country={country}
          />
        </div>
        <Button disabled={isSubmitButtonDisabled} loading={isLoading} />
      </form>

      {error && !isMobile ? <FormMessage message={error} type="error" /> : null}
      {formSubmittedSubmittedSuccessfully && !isMobile ? (
        <FormMessage message={formSubmittedSubmittedSuccessfully} type="success" />
      ) : null}
    </div>
  );
};

import React, { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { prepareSMSURI, setTheme } from '../../../utils/general';
import { APP_THEMES } from '../../../constants/app';
import { SmsInstantRedirectConfigItem } from '../../configs/smsInstantRedirectConfig';

type Props = SmsInstantRedirectConfigItem

const DCBSms: React.FC<Props> = ({shortCode, body}) => {

  useEffect(() => {
    setTheme(APP_THEMES.WHITE)
  }, [])

  useEffect(() => {
    if(isDesktop) return

    const smsUri = prepareSMSURI(shortCode, body);
    window.location = smsUri as Location | (string & Location)
  }, [shortCode, body])

  return isDesktop ? <p>Service only supports Android and IOS Devices</p> : null
};

export default DCBSms;

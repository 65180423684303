import FacebookPixel from 'react-facebook-pixel';

export class FacebookService {
  static initialized: boolean;

  constructor() {
    FacebookService.init();
  }

  static init(force = false) {
    if (!FacebookService.initialized || force) {
      // const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      FacebookPixel.init('981697515182465', undefined, options);
      FacebookService.initialized = true;
    }
  }

  public static trackSubscribe(price?: string) {
    FacebookPixel.track('Subscribe', {
      value: price || 'USD',
      currency: 'USD',
      predicted_ltv: price || 'USD',
    });
  }

}

import React from 'react';
import { Button } from 'react-bootstrap';
import { setUserSelectedLanguageManually } from '../../../store/dcb';
import { changeLanguage } from '../../../store/global';
import { setHtmlDirection } from '../../../utils';
import { LogService } from '../../../services/LogService';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../store/hooks';
import styles from './B2BLanguageDropdown.module.css'
import { LanguageSelector } from '../../assets/svgs/LanguageSelector';
import { EN_lang, AR_lang } from '../../../constants/languages';
import { getDirFromLocale } from '../../../utils/general';

export const B2BLanguageDropdown: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { language } = useAppSelector(({ global, dcb }) => ({
    language: global.language,
  }));

  const nextLang = language.locale === EN_lang.lang ? AR_lang : EN_lang
  
  const handleChangeLanguage = () => {
    const nextLang = language.locale === EN_lang.lang ? AR_lang : EN_lang

    i18n.changeLanguage(nextLang.lang);
    dispatch(changeLanguage({ locale: nextLang.lang, displayName: nextLang.name, direction: nextLang.direction }));

    const dir = getDirFromLocale(nextLang.lang);
    setHtmlDirection(dir);

    dispatch(setUserSelectedLanguageManually());
    LogService.languageChangedManually(nextLang.lang)
  };

  return (
    <Button
      variant={'default'}
      onClick={handleChangeLanguage}
    >
      <div className={styles.dropdown_selected_item}>
        <LanguageSelector />
        <span>{nextLang.name}</span>
      </div>
    </Button>
  );
}
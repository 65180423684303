export const arLocale = 'ar';

export const EN_lang = {
  lang: 'en',
  name: 'English',
  direction: 'ltr',
  checkData: false,
}

export const AR_lang = {
  lang: arLocale,
  name: 'العربية',
  direction: 'rtl',
  checkData: false,
}

export const FR_lang = {
  lang: 'fr',
  name: 'Français',
  direction: 'ltr',
  checkData: false,
}

export const ES_lang = {
  lang: 'es',
  name: 'Español',
  direction: 'ltr',
  checkData: false,
}

export const PT_lang = {
  lang: 'pt',
  name: 'Português',
  direction: 'ltr',
  checkData: false,
}

export const TR_lang = {
  lang: 'tr',
  name: 'Türkçe',
  direction: 'ltr',
  checkData: false,
}

export const KR_lang = {
  lang: 'kr',
  name: 'کوردی',
  direction: 'rtl',
  checkData: false,
};

const languages = [
  EN_lang,
  AR_lang,
  FR_lang,
  ES_lang,
  PT_lang,
];

export const languagesNames = [
  'en', 'ar', 'fr', 'es', 'pt', 'tr', 'id', 'ms', 'ur', 'no', 'kr'
]

export default languages;

import {
  APP_THEMES,
  BLUE_THEME_CLASS_NAME,
  PAYMENT_METHODS,
  WHITE_THEME_CLASS_NAME,
  routesThatShowsAd,
  VARIATION_KEYS,
  PAYMENT_GATEWAYS,
} from '../constants/app';
import { isAndroid, isIOS, isMacOs } from 'react-device-detect';
import { languagesNames } from '../constants/languages';
import { defaultBannerConfig } from '../constants/Banners';
import { getURLSependingOnOS } from './getURLSependingOnOS';
import { SPECIFIC_API_SOURCES_FOR_OPERATOR_TEXT, SPECIFIC_API_SOURCES_FOR_OPERATOR_THIRD_TEXT } from '../constants/apiSources';
import parsePhoneNumber from 'libphonenumber-js/mobile';

export const appendTextScript = (textScript, delay = 500) => {
  const fraudScript = document.createElement('script');
  fraudScript.textContent = textScript;
  fraudScript.type = 'text/javascript';

  if (delay > 0){
    setTimeout(() => {
      document.head.appendChild(fraudScript);
    }, delay);
  } else {
    document.head.appendChild(fraudScript);
  }
};

export const appendSrcScript = (scriptSrc, onLoad = null) => {
  const fraudScript = document.createElement('script');
  fraudScript.src = scriptSrc;
  fraudScript.type = 'text/javascript';

  if (onLoad) {
    fraudScript.onload = onLoad;
  }

  document.head.appendChild(fraudScript);
};

export const appendTextScriptFromURL = (url) => {
  const req = new XMLHttpRequest();
  req.open('GET', url, false);
  req.send(null);
  appendTextScript(req.response);

  return req;
};

export const isRTL = (direction) => {
  return direction === 'rtl'
}

export const getDirFromLocale = (locale) => {
  return locale === 'ar' || locale === 'kr'  ? 'rtl' : 'ltr'
}

export const isCC = (paymentMethod) => paymentMethod?.toLowerCase() === PAYMENT_METHODS.Card.toLowerCase()
export const isDCB = (paymentMethod) => paymentMethod?.toLowerCase() === PAYMENT_METHODS.Phone.toLowerCase()
export const isApplePay = (paymentMethod) => paymentMethod?.toUpperCase() === PAYMENT_METHODS.APPLE_PAY
export const isGooglePay = (paymentMethod) => paymentMethod?.toUpperCase() === PAYMENT_METHODS.GOOGLE_PAY
export const isAppleOrGooglePay = (paymentMethod) => isApplePay(paymentMethod) || isGooglePay(paymentMethod)

export const isWhiteDependingOnDOM = () => {
  // this is done like this because isWhite is prop managed,
  // but in some cases we have components that are used out of prop,
  // f.e. ConfirmPin route
  return document.getElementsByTagName('body')[0].classList?.[0] === WHITE_THEME_CLASS_NAME
}

export const setTheme = (theme) => {
  if(theme === APP_THEMES.WHITE) {
    document.getElementsByTagName('body')[0].classList.add(WHITE_THEME_CLASS_NAME)
  } else if(theme === APP_THEMES.BLUE) {
    document.getElementsByTagName('body')[0].classList.add(BLUE_THEME_CLASS_NAME)
  }
}

export const isWhiteTheme = (theme) => theme === APP_THEMES.WHITE

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const redirectToStore = () => {
  if (isAndroid) {
    window.location =
      'https://play.google.com/store/apps/details?id=com.creativeinnovations.mea&hl=en';
  } else if (isIOS) {
    window.location =
      'https://apps.apple.com/us/app/360-vuz-live-vr-video-views/id964118383';
  }
}

export const shouldLoadFoxPush = (pathname) => {
  return Object.keys(routesThatShowsAd).find((key) => {
    return pathname.includes(key)
  })
}

export const getFinalNumberFromDialCode = (defaultValue, dialCode) => {
  let finalNumber = defaultValue;

  if (finalNumber.startsWith(`00${dialCode}0`)) {
    finalNumber = finalNumber.replace(`00${dialCode}0`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`00${dialCode}`)) {
    finalNumber = finalNumber.replace(`00${dialCode}`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`0${dialCode}0`)) {
    finalNumber = finalNumber.replace(`0${dialCode}0`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`0${dialCode}`)) {
    finalNumber = finalNumber.replace(`0${dialCode}`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`+${dialCode}0`)) {
    finalNumber = finalNumber.replace(`+${dialCode}0`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`+${dialCode}`)) {
    finalNumber = finalNumber.replace(`+${dialCode}`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`${dialCode}0`)) {
    finalNumber = finalNumber.replace(`${dialCode}0`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`${dialCode}`)) {
    finalNumber = finalNumber.replace(`${dialCode}`, `+${dialCode}`);
  } else if (finalNumber.startsWith(`0`)) {
    finalNumber = finalNumber.replace(`0`, ``);
  }

  return finalNumber;
}

export const specialLanguagesForInputFields = [...languagesNames, 'headerenrichment'];

export const checkSpecialLanguagesForInputFields = (language) => {
  return specialLanguagesForInputFields.includes(language)
}

export const isUniqueApiSource = (apiSource) => {
  return apiSource === 'api-web-uae-tdra'
}

export const isApplePayAvailable = () => window.ApplePaySession?.canMakePayments()

export const getJsonParsed = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export const isVariationV3Route = (variationKey) => variationKey === VARIATION_KEYS.v3
export const isVariationV4Route = (variationKey) => variationKey === VARIATION_KEYS.v4
export const isVariationV5Route = (variationKey) => variationKey === VARIATION_KEYS.v5

export const isStripePaymentGateway = (gateway) => gateway === PAYMENT_GATEWAYS.STRIPE;

export const prepareSMSURI = (shortCode, body) => {
  return `sms:${shortCode}${(isIOS || isMacOs) ? '&' : `?`}body=${body}`
}
export const prepareSMSURIForInAppRedirect = (shortCode, body) => {
  return `navigation=textMessage&phoneNumber=${shortCode}&body=${body}`
}

export const getVideoBannerUrl = (bannerConfing = defaultBannerConfig, locale = 'en') =>
  getURLSependingOnOS(bannerConfing[locale]) || bannerConfing[locale] || getURLSependingOnOS(bannerConfing['en'])

export const isEmailValid = (value) => {
  const EMAIL_REG_EXP = /^[a-zA-Z0-9._]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;

  const emailRegexp = new RegExp(EMAIL_REG_EXP);
  return emailRegexp.test(value);
}
export const isNameValid = (value) => {
  return isNameEnoughLong(value) && isNameEnoughShort(value);
}

export const isNameEnoughLong = (value) => {
  return value?.length > 3;
}

export const isNameEnoughShort = (value) => {
  return value?.length <= 25;
}

export const isPhoneValid = (value, country) => {
  return parsePhoneNumber(value, country)?.isValid();
}

export const isSpecialApiSourceForOperatorText = (apiSource) => {
  return SPECIFIC_API_SOURCES_FOR_OPERATOR_TEXT.includes(apiSource)
}

export const isSpecialApiSourceForOperatorThirdText = (apiSource) => {
  return SPECIFIC_API_SOURCES_FOR_OPERATOR_THIRD_TEXT.includes(apiSource)
}

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { LogService } from '../services/LogService';
import Helpers from '../utils/Helpers';
import languages from '../constants/languages';
import additionalLanguages from '../constants/additionalLanguages';
import { changeLanguage } from '../store/global';
import { setHtmlDirection } from '../utils';

export const useLanguage = (shouldIgnore = false) => {
  const routeParams = useParams();
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldIgnore) {
      return;
    }
    const logService = new LogService();
    logService.init();
    const locale = routeParams.lang || Helpers.getUrlParameter('lang') || navigator?.language?.slice(0,2)

    if (locale) {
      const supportedLanguage = [...languages, ...additionalLanguages].find(el => el.lang === locale)

      if(supportedLanguage){
        const language = {
          locale,
          displayName: supportedLanguage.name,
          direction: supportedLanguage.direction,
        }
        i18n.changeLanguage(locale);
        dispatch(changeLanguage(language));
        setHtmlDirection(supportedLanguage.direction);
      }
    }
  }, [dispatch, i18n]);

}
import { PAYMENT_GATEWAYS, PAYMENT_METHODS } from "../constants/app";
import { browserName, osName } from "react-device-detect";
import { LogService } from '../services/LogService';

export const startFlowLogger = async (inputFields, apiSource, manualPaymentMethod, countryFromIP = null, paymentGateway = PAYMENT_GATEWAYS.STRIPE) => {
    const {
      lang,
      country,
      subscriberId,
      mcc,
      mnc,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      postAffiliateProName,
      postAffiliateProData1,
      postAffiliateProData2,
      postAffiliateProData3
     } = inputFields || {}

    const userProperties = {
      country: country ? country : countryFromIP,
      language: lang ? lang : 'en',
      subscriberId,
      mcc,
      mnc,
      source: apiSource,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      postAffiliateProName,
      postAffiliateProData1,
      postAffiliateProData2,
      postAffiliateProData3,
      browser: browserName,
      os: osName,
      state: 'lead',
    };

    if (manualPaymentMethod === PAYMENT_METHODS.Card) {
      LogService.ccStartFlow(userProperties, { paymentGateway })
    } else {
      LogService.dcbStartFlow(userProperties)
    }
}

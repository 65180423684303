export const WEB_NEW_LOOKUP = 'api-web-new-lookup';
export const WEB_UAE_TDRA = 'api-web-uae-tdra';
export const WEB_LOOKUP = 'api-web-lookup';
export const WEB_MIN = 'api-web-min';
export const WEB_NEW = 'api-web-new';
export const WEB_NEW_V2 = 'api-web-new-v2';
export const APP = 'api-app';
export const WEB = 'api-web';

export const API_SOURCE_WEB_INITIATIVE = 'api-web-Initiative_MY_KSA_UAE_i1'

export const WIDE_V3_LAYOUT_API_SOURCE = 'api-web-androidtv'

export const WEB_STEPS_BLACK = 'api-web-Steps_Black_AR';
export const WEB_STEPS_WHITE = 'api-web-Steps_White_AR';
export const WEB_LOADED_WHITE = 'api-web-Loaded_White_AR';

export const WEB_STEPS_BLACK_EN = 'api-web-Steps_Black_EN';
export const WEB_CHECKMARK_WHITE_EN = 'api-web-checkmark_white_EN';
export const WEB_CHECKMARK_BLACK_EN = 'api-web-Checkmark_Black_EN';

export const V5_CHECKMARK = 'api-web-check_mark';
export const V5_DOWNLOAD_ICON = 'api-web-download_icon';
export const V5_PHONE_ICON = 'api-web-phone_icon';

export const V5_CHECKMARK_WEEKLY = 'api-web-check_mark_weekly';
export const V5_DOWNLOAD_ICON_WEEKLY = 'api-web-download_icon_weekly';
export const V5_PHONE_ICON_WEEKLY = 'api-web-phone_icon_weekly';

export const SPECIFIC_API_SOURCES_FOR_OPERATOR_TEXT = [
  WEB_STEPS_BLACK_EN,
  WEB_CHECKMARK_WHITE_EN,
  WEB_CHECKMARK_BLACK_EN,
  V5_CHECKMARK,
  V5_DOWNLOAD_ICON,
  V5_PHONE_ICON,
  V5_CHECKMARK_WEEKLY,
  V5_DOWNLOAD_ICON_WEEKLY,
  V5_PHONE_ICON_WEEKLY,
];
export const SPECIFIC_API_SOURCES_FOR_OPERATOR_THIRD_TEXT = [WEB, WEB_NEW];

import React, { useContext, useEffect, useState } from 'react';
import './AppLoadingContextStyle.css'
import { useAppSelector } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useScrollLock } from '../../../hooks/useScrollLock';
import { isVariationV3Route } from '../../../utils/general';

interface IAppLoadingContext {
  isLoading: boolean
}

export const AppLoadingContext = React.createContext<IAppLoadingContext>({
  isLoading: false,
});

interface Props {
  children: React.ReactNode
}

export const _useAppLoadingContext = () => {
  const [isLoading, setIsLoading] = useState(false);

  return {
    isLoading,
    setIsLoading,
  }
}

export const useAppLoadingContext = () => useContext(AppLoadingContext)

export const AppLoadingContextProvider: React.FC<Props> = ({ children }) => {
  const appLoadingContextValue = _useAppLoadingContext()
  const { t } = useTranslation()
  const [lockScroll, unlockScroll] = useScrollLock()
  const { isV3Route } = useAppSelector(({ dcb }) => ({
    isV3Route: isVariationV3Route(dcb.variationKey)
  }));

  useEffect(() => {
    if (appLoadingContextValue.isLoading) {
      lockScroll()
    } else {
      unlockScroll()
    }
  }, [appLoadingContextValue.isLoading, lockScroll, unlockScroll])


  return <AppLoadingContext.Provider value={appLoadingContextValue}>
    {
      appLoadingContextValue.isLoading
        ? (
          !isV3Route ?
            (
              <>
                <div className="app_loading_container">
                  <div className="app_loading" />
                </div>
                <div className='app_loading_wrapper'/>
              </>
            ) : (
              <>
              <div className='v3_loader_container'>
                <div className='v3_dot_spin'></div>
                <div className='v3_loader_text'>{t('payment_processing')}</div>
              </div>
              <div className='v3_loader_wrapper' />
              </>
              )
        ) : null
    }
    {children}
  </AppLoadingContext.Provider>
};

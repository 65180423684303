import React from "react"

const LoaderFreshSVG = ({color = "white", width = "32", height = "32"}) => {
    return (
            <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="path-1-inside-1_1402_19323" fill={color}>
                    <path d="M16 2.04056C16 0.913587 16.9173 -0.0134056 18.0351 0.129937C20.4782 0.443235 22.8258 1.31782 24.8891 2.69648C27.5203 4.45459 29.5711 6.95345 30.7821 9.87706C31.9931 12.8007 32.3099 16.0177 31.6926 19.1214C31.0752 22.2251 29.5513 25.0761 27.3137 27.3137C25.0761 29.5513 22.2251 31.0752 19.1214 31.6926C16.0177 32.3099 12.8007 31.9931 9.87706 30.7821C6.95345 29.5711 4.45459 27.5203 2.69649 24.8891C1.31783 22.8258 0.443235 20.4782 0.129937 18.0351C-0.0134046 16.9173 0.913588 16 2.04056 16C3.16752 16 4.06335 16.9202 4.25536 18.0306C4.53796 19.6651 5.15999 21.2302 6.08981 22.6218C7.39947 24.5818 9.26095 26.1095 11.4388 27.0116C13.6167 27.9137 16.0132 28.1498 18.3253 27.6899C20.6373 27.23 22.761 26.0948 24.4279 24.4279C26.0948 22.761 27.23 20.6373 27.6899 18.3253C28.1498 16.0132 27.9137 13.6167 27.0116 11.4388C26.1095 9.26095 24.5818 7.39947 22.6218 6.08981C21.2302 5.15999 19.6651 4.53796 18.0306 4.25536C16.9202 4.06335 16 3.16753 16 2.04056Z"/>
                </mask>
                <path d="M16 2.04056C16 0.913587 16.9173 -0.0134056 18.0351 0.129937C20.4782 0.443235 22.8258 1.31782 24.8891 2.69648C27.5203 4.45459 29.5711 6.95345 30.7821 9.87706C31.9931 12.8007 32.3099 16.0177 31.6926 19.1214C31.0752 22.2251 29.5513 25.0761 27.3137 27.3137C25.0761 29.5513 22.2251 31.0752 19.1214 31.6926C16.0177 32.3099 12.8007 31.9931 9.87706 30.7821C6.95345 29.5711 4.45459 27.5203 2.69649 24.8891C1.31783 22.8258 0.443235 20.4782 0.129937 18.0351C-0.0134046 16.9173 0.913588 16 2.04056 16C3.16752 16 4.06335 16.9202 4.25536 18.0306C4.53796 19.6651 5.15999 21.2302 6.08981 22.6218C7.39947 24.5818 9.26095 26.1095 11.4388 27.0116C13.6167 27.9137 16.0132 28.1498 18.3253 27.6899C20.6373 27.23 22.761 26.0948 24.4279 24.4279C26.0948 22.761 27.23 20.6373 27.6899 18.3253C28.1498 16.0132 27.9137 13.6167 27.0116 11.4388C26.1095 9.26095 24.5818 7.39947 22.6218 6.08981C21.2302 5.15999 19.6651 4.53796 18.0306 4.25536C16.9202 4.06335 16 3.16753 16 2.04056Z" stroke="url(#paint0_linear_1402_19323)" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" mask="url(#path-1-inside-1_1402_19323)"/>
                <defs>
                    <linearGradient id="paint0_linear_1402_19323" x1="-2.60153e-06" y1="20.5714" x2="20.5714" y2="5.71429" gradientUnits="userSpaceOnUse">
                        <stop stopColor={color}/>
                        <stop offset="0.921875" stopColor="white" stopOpacity="0"/>
                    </linearGradient>
                </defs>
            </svg>
    )
}

export default LoaderFreshSVG
import React, { useState } from 'react';
import styles from './B2BFormField.module.css';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { isEmailValid, isNameEnoughLong, isNameEnoughShort, isNameValid, isPhoneValid } from '../../../utils/general';

interface Props {
  error: boolean;
  onError: React.Dispatch<React.SetStateAction<boolean>>;
  value: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  name: string;
  country?: string;
}

export const B2BFormField: React.FC<Props> = ({
  error,
  onError,
  value,
  onChange,
  placeholder = 'Enter here',
  name,
  country,
}) => {
  const { t } = useTranslation();
  const [errorKeyInternal, setErrorKeyInternal] = useState<any>('');

  const handleChange = (name, e: React.ChangeEvent<HTMLInputElement>) => {
    onError(false);
    setErrorKeyInternal('');
    let value = e.target.value

    if (name === 'name' && value.length > 25) return;
    
    if (name === 'phoneNumber') {
      value = value.replaceAll(' ', '').replace(/[^0-9+]/g, '');
    }
    onChange(value);
  };

  const handleBlur = () => {
    validateInput();
  };

  const validateInput = () => {
    let errorMessageKey = '';
    if (name === 'email') {
      const isValid = isEmailValid(value)
      if (!isValid) {
        errorMessageKey = 'invalid_field_value_email';
      }
    }
    if (name === 'name') {
      const isValid = isNameValid(value)
      if (!isValid) {
        if (isNameEnoughLong(value)) {
          errorMessageKey = 'invalid_field_value_name_long';
        } else if (isNameEnoughShort(value)) {
          errorMessageKey = 'invalid_field_value_name';
        }
      }
    }
    if (name === 'phoneNumber') {
      const isValid = isPhoneValid(value, country)
      if (!isValid) {
        errorMessageKey = 'invalid_field_value_phoneNumber';
      }
    }

    setErrorKeyInternal(errorMessageKey);
    onError(!!errorMessageKey)
  };

  return (
    <div className={styles.inputWrapper}>
      <input
        value={value}
        onChange={(e) => {handleChange(name, e)}}
        onBlur={handleBlur}
        className={cn(styles.input, {
          [styles.inputError]: error,
        })}
        placeholder={placeholder}
        name={name}
      />
      {error ? <p className={styles.fieldError}>{t(errorKeyInternal, t(`invalid_field_value`))}</p> : null}
    </div>
  );
};

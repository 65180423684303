const env = process.env.REACT_APP_ENV || 'PROD';

let Config = {};

if (env === 'DEV') {
  Config = {
    CHECKOUT_URL: 'https://api.sandbox.checkout.com/tokens',
    PUBLIC_KEY: 'pk_test_818e9fd6-5747-4ff6-84f1-321e26a490ae',
    API_URL: 'https://beta-plus-api.360vuz.com',
    LEGACY_API_URL: 'https://dev-backend-mobile.360meatech.com',
    WEB_PAGE_URL: 'https://plusbeta.360vuz.com',
    STRIPE_KEY:
      'pk_test_51LAV43HGV972IYkMOtbjltrLif3sxbJXN0YyzaajXhtKBBNYJhB3cG9IVFqLL0lRANJF7vptHs8c4EI9bm0OKKSC00y4r9dNN1',
    ADYEN_KEY: 'test_ODOC36GYJRGO5OKEO5ID5FNA3EHZDLA6',
    EMPELLO_KEY: '5aARXE5TprtYnNnt',
  };
} else if (env === 'PROD') {
  Config = {
    CHECKOUT_URL: 'https://api.checkout.com/tokens',
    PUBLIC_KEY: 'pk_11fc4a3c-b859-43d1-a6a7-24b2c3856fa8',
    API_URL: 'https://prod-plus-api.360vuz.com',
    LEGACY_API_URL: 'https://backend-mobile.360meatech.com',
    WEB_PAGE_URL: 'https://plus.360vuz.com',
    STRIPE_KEY:
      'pk_live_51LAV43HGV972IYkMGLRHsOUcdaaOgnhlfGt5nCfIW0Adjinq5SA3Y0gG7VzzfHikvARtk7uFuU5oknTEoTev3mtQ008PNre37g',
    ADYEN_KEY: 'live_GQQ3RCYJX5BKJPLK6I5Y6VGA3USMOD6N',
    EMPELLO_KEY: '5aARXE5TprtYnNnt',
  };
}

export const isDev = () => process.env.REACT_APP_ENV === "DEV"

export default Config;

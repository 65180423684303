// TYPES
const SHOW_REDIRECT_TO_CC_MODAL = 'SHOW_REDIRECT_TO_CC_MODAL';
const HIDE_REDIRECT_TO_CC_MODAL = 'HIDE_REDIRECT_TO_CC_MODAL';

// INITIAL STATE
const redirectToCCModal_DEFAULT = {
  show: false,
};

export type AppUIState = {
  redirectToCCModal: {
    show: boolean,
    title?: {
      value?: string,
      translateKey?: string,
    },
    body?: {
      value?: string,
      translateKey?: string,
    },
  }
}

const initialState: AppUIState = {
  redirectToCCModal: redirectToCCModal_DEFAULT,
};

// REDUCER
export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SHOW_REDIRECT_TO_CC_MODAL:
      return { ...state, redirectToCCModal: payload };
    case HIDE_REDIRECT_TO_CC_MODAL:
      return { ...state, redirectToCCModal: redirectToCCModal_DEFAULT };
    default:
      return state;
  }
}

// ACTIONS
export const showRedirectToCCModal =
  (title, body) =>
    (dispatch) => {
      dispatch({
        type: SHOW_REDIRECT_TO_CC_MODAL,
        payload: { show: true, title: title, body: body },
      });
    };

export const hideRedirectToCCModal =
  () =>
    (dispatch) => {
      dispatch({
        type: HIDE_REDIRECT_TO_CC_MODAL,
      });
    };

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './Modal.module.css';
import { ErrorIcon } from '../icons/ErrorIcon';
import { SuccessTickIcon } from '../icons/SuccessTickIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  show: boolean;
  type?: 'normal' | 'error' | 'success';
  onClose: () => void;
  title?: {  value?: string, translateKey?: string, };
  body?: {  value?: string, translateKey?: string, };
  secondaryText?: string | null;
  onSecondaryAction?: () => void;
  primaryText?: string;
  onPrimaryAction?: () => void;
  withHeaderIcon?: boolean;
}

export const AppModal: React.FC<Props> = (
  {
    show,
    onClose,
    type = 'normal',
    title,
    body,
    secondaryText,
    onSecondaryAction,
    primaryText,
    onPrimaryAction,
    withHeaderIcon= false
  },
) => {
  const { t } = useTranslation()

  const secondaryExists = secondaryText && onSecondaryAction;
  const primaryExists = primaryText && onPrimaryAction;
  const footerClassName = secondaryExists && primaryExists ? styles.modal_footer_before : '';
  let titleClassName = `${styles.title}`;
  if (type === 'error') {
    titleClassName += ` ${styles.title_error}`;
  }
  if (type === 'success') {
    titleClassName += ` ${styles.title_success}`;
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        animation={true}
        backdrop
        centered
        contentClassName={styles.modal_content}
      >
        <Modal.Header className={styles.modal_header}>
          {
            withHeaderIcon
              ? <HeaderIcon type={type || 'normal'} />
              : null
          }
          {
            title && (title.value || title.translateKey)
              ? <Modal.Title className={titleClassName}>{title.value || t(title.translateKey || '')}</Modal.Title>
              : null
          }
        </Modal.Header>
        {
          body && (body.value || body.translateKey)
            ? <Modal.Body className={styles.modal_body}>{body.value || t(body.translateKey || '')}</Modal.Body>
            : null
        }

        {
          (secondaryExists || primaryExists)
            ? (
              <Modal.Footer className={footerClassName}>
                {
                  secondaryText && onSecondaryAction
                    ? <span className={styles.modal_button_secondary} onClick={onSecondaryAction}>
            {secondaryText}
          </span>
                    : null
                }

                {
                  primaryText && onPrimaryAction
                    ? <span className={styles.modal_button_primary} onClick={onPrimaryAction}>
            {primaryText}
          </span>
                    : null
                }
              </Modal.Footer>
            )
            : null
        }
      </Modal>
    </>
  );
};

const HeaderIcon: React.FC<Pick<Props, 'type'>> = ({ type }) => {
  if (type === 'error') {
    return <ErrorIcon />
  }
  if (type === 'success') {
    return <SuccessTickIcon />
  }

  return null;
}
import { banners } from '../../../constants/Banners';

export const HRLLookupBanner = {
  en: banners.main_video_new_en,
  ar: banners.main_video_new_ar,
  isVideo: true,
};

// interface IFollowUs {
//   en: english string url
//   ar: arabic string url
//   isImage: boolean type // if true, render video
//   isVideo: boolean type // if true, render image
// }

import React from 'react';
import { CLOUDFRONT_LOGOS_ROOT_URL } from '../../../constants/app';
import styles from './B2BFormText.module.css';
import cn from 'classnames'
import { useTranslation } from 'react-i18next';
import { getDirFromLocale, isRTL } from '../../../utils/general';
import { useAppSelector } from '../../../store/hooks';

const listOfHints = [
  {
    'secondary': 'b2b_hint_1_1',
    'primary': 'b2b_hint_1_2'
  },
  {
    'secondary': 'b2b_hint_2_1',
    'primary': 'b2b_hint_2_2'
  },
  {
    'secondary': 'b2b_hint_3_1',
    'primary': 'b2b_hint_3_2'
  },
];

export const B2BFormText: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useAppSelector((state) => state.global);
  const dir = getDirFromLocale(language.locale);

  const prepareHintText = (listOfHint) => {
    if (dir === 'rtl') {
      return <>{t(listOfHint.secondary) + ' ' + t(listOfHint.primary)}</>
    }

    return <>{t(listOfHint.secondary)} {<span className={styles.hints_primary_text}>{t(listOfHint.primary)}</span>}</>
  }

  return (<>
    <div className={styles.logos_wrapper}>
      <img src={`${CLOUDFRONT_LOGOS_ROOT_URL}/vuz/vuz.png`} alt='logo' className={styles.vuz_logo} />
      <span className={styles.logo_gluer}>{t("on")}</span>
      <img src={`${CLOUDFRONT_LOGOS_ROOT_URL}/vuz/vision_pro.png`} alt='vision pro logo' className={styles.vision_pro_logo} />
    </div>
    <ul className={cn(styles.hints_wrapper, {
      [styles.hints_wrapper_right]: isRTL(language.direction)
    })}>
      {
        listOfHints.map((listOfHint) => {
          return (
            <li key={listOfHint.secondary}>
              {prepareHintText(listOfHint)}
            </li>
          );
        })
      }
    </ul>
  </>);
}